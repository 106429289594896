import Api from "../api";

const basePath = "Location";

// Cache simples para armazenar resultados e os parâmetros utilizados
const stateCache = {
    get: {}, // Cache para a função `get`
    formLocation: {
        params: null,
        data: null,
    },
    getAll: {
        params: null,
        data: null,
    },
    getAllSimple: {
        data: null,
        loading: false, // Adiciona um estado de carregamento para evitar múltiplas chamadas simultâneas
    },
    getLocationByCompanyId: null,
};

// Função auxiliar para comparar objetos
const areParamsEqual = (prevParams, newParams) => {
    return JSON.stringify(prevParams) === JSON.stringify(newParams);
};

export function get(id, languageId, includeChildren = false, includeDirectParents = false) {
    let url = `/${basePath}/get?tenantLocationId=${id}`;
    if (languageId) {
        url += "&languageId=" + languageId;
    }
    if (includeChildren) {
        url += "&includeChildren=true";
    }
    if (includeDirectParents) {
        url += "&includeDirectParents=true";
    }

    // Verifica o cache com base nos parâmetros
    const cacheKey = `${id}_${languageId}_${includeChildren}_${includeDirectParents}`;
    if (stateCache.get[cacheKey]) {
        return Promise.resolve(stateCache.get[cacheKey]);
    }

    // Caso não tenha cache, realiza a requisição
    return Api.get(url).then((response) => {
        stateCache.get[cacheKey] = response; // Armazena no cache
        return response;
    });
}

export async function formLocation(
    params = {
        languageId: undefined,
        locationsId: [],
        includeChildren: false,
        includeDirectParents: false,
    }
) {
    // Verifica se os parâmetros são iguais aos do cache
    if (stateCache.formLocation.data && areParamsEqual(stateCache.formLocation.params, params)) {
        return stateCache.formLocation.data;
    }

    const result = await Api.post(`/${basePath}/FormLocations`, params);
    stateCache.formLocation = { params, data: result }; // Armazena no cache
    return result;
}

export async function getAll(
    params = {
        languageId: undefined,
        locationsId: [],
        includeChildren: false,
        includeDirectParents: false,
    }
) {
    // Verifica se os parâmetros são iguais aos do cache
    if (stateCache.getAll.data && areParamsEqual(stateCache.getAll.params, params)) {
        return stateCache.getAll.data;
    }

    const result = await Api.post(`/${basePath}/getAll`, params);
    stateCache.getAll = { params, data: result }; // Armazena no cache
    return result;
}

export async function getAllSimple() {
    // Verifica se a requisição está em progresso ou se já existe um resultado em cache
    if (stateCache.getAllSimple.loading) {
        // Se já estiver carregando, retorna uma promessa pendente até a requisição terminar
        return new Promise((resolve) => {
            const checkInterval = setInterval(() => {
                if (!stateCache.getAllSimple.loading) {
                    clearInterval(checkInterval);
                    resolve(stateCache.getAllSimple.data);
                }
            }, 50);
        });
    }

    // Se já existe cache, retorna o valor armazenado
    if (stateCache.getAllSimple.data) {
        return stateCache.getAllSimple.data;
    }

    // Define o estado de carregamento como verdadeiro
    stateCache.getAllSimple.loading = true;

    try {
        const result = await Api.post(`/${basePath}/getAllSimple`, null);
        stateCache.getAllSimple = { data: result, loading: false }; // Atualiza o cache e redefine o estado de carregamento
        return result;
    } catch (error) {
        stateCache.getAllSimple.loading = false;
        throw error; // Repassa o erro para que o chamador possa tratá-lo
    }
}

export async function getLocationByCompanyId() {
    if (stateCache.getLocationByCompanyId) {
        return stateCache.getLocationByCompanyId;
    }

    const result = await Api.get(`/${basePath}/Company/getAll`);
    stateCache.getLocationByCompanyId = result; // Armazena no cache
    return result;
}

/**
 * @typedef {Object} locationUpdateParams
 * @property {string} locationId
 * @property {string} languageId
 * @property {string} name
 * @param {locationUpdateParams[]} listTranslations
 */
export async function locationUpdateTranslations(listTranslations) {
    const result = await Api.post(`/${basePath}/updateTranslation`, listTranslations);
    return result;
}
