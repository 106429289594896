import { LockOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { error_message, success_message } from "../../components/Toastr";
import { useAuth } from "../../hooks/auth";
import Api, { exceptionNotificationAPI } from "../../services/api";

const Profile = () => {
  const {t, i18n} = useTranslation();
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const { user, updateUser, signOut } = useAuth();

  const handleSubmit = useCallback((data) => {

    if(data.confirmPassword !== data.newPassword){
      error_message("Please verified your password!");
      return false;
    }

    let objProfileBackend = {
        id: user.id,
        password: data.password,
        newPassword: data.newPassword,
        Profile:[]
    };
    
    Api.post(`/User/changePassword`,objProfileBackend)
      .then((response) => {
          if(response.data){
            success_message("Password Changed!");
            form.resetFields();
            signOut();
            
          }
      })
      .catch(error => { exceptionNotificationAPI(error);});
              
  },[history, updateUser]);
    
  return (
    
    <Form
      form={form}
      name="form_profile"
      layout="vertical"
      onFinish={handleSubmit}
      requiredMark={false}
    >
       <Form.Item
          label={t('Login.CurrentPassw')}
          name="password"
          rules={[
            { 
              required: true,  
              whitespace: true, 
              message: t('Required.Custom.Message',{
                grammaticalPunct: '!', fieldName: t('Login.CurrentPassw').toLowerCase()
              })
            }
        ]}
      >
          <Input size="large" type="password"  prefix={<LockOutlined />}/>
      </Form.Item>

      <Form.Item
          label={t('Login.NewPassw')}
          name="newPassword"
          rules={[
            { 
              required: true,  
              whitespace: true, 
              message: t('Required.Custom.Message',{
                grammaticalPunct: '!', fieldName: t('Login.NewPassw').toLowerCase()
              })
            }
        ]}
      >
          <Input size="large" type="password"  prefix={<LockOutlined />}/>
      </Form.Item>

      <Form.Item
          label={t('Login.ConfirmPassw')}
          name="confirmPassword"
          rules={[
              { 
                  required: true, 
                  whitespace: true,
                  message: t('Required.Custom.Message',{
                    grammaticalPunct: '!', fieldName: t('Login.ConfirmPassw').toLowerCase()
                  })
              }
          ]}
      >
          <Input size="large" type="password"  prefix={<LockOutlined />}/>
      </Form.Item>

      <Row gutter={16}>
          <Col span={24} style={{ textAlign: 'right' }}>
              <Button key="back" onClick={() => form.resetFields()}>{t('BtnReset')}</Button>
              <Divider type="vertical" />
              <Button key="submit" htmlType="submit" type="primary">{t('BtnSave')}</Button>
          </Col>
      </Row>
    </Form>
  );
};

export default Profile;