import * as React from "react";
import { TreeSelectLocationBase } from "./index";

import { useAuth } from '../../hooks/auth';

export function TreeSelectLocationUser(props) {
    const { baseLocation, ...propsToPropagate } = props;

    const { user } = useAuth();

    return(
        <TreeSelectLocationBase
            baseLocation={baseLocation ?? user.locationId}
            {...propsToPropagate}
        />
    );
}
