import { notification } from "antd";
import { FaCheck, FaExclamationTriangle, FaInfo, FaMinusCircle } from "react-icons/fa";

notification.config({
  placement: 'top',
});

export function success_message(message) {
  notification.success({
    message: message,
  });
}

export function info_message(message) {
  notification.info({
    message: message,
  });
}

export function warning_message(message) {
  notification.warning({
    message: message,
  });
}

export function error_message(message) {
  notification.error({
    message: message,
  });
}

export class NotificationMessagesV2 {
  static successMessage(message) {
    notification.success({
      message: message,
    });
  }

  static errorMessage(message) {
    notification.error({
      message: message,
    });
  }
}