import { Button, Checkbox, Col, DatePicker, Divider, Form, Modal, Row, Select, Switch, TreeSelect } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from '../../components/Loading';
import { SelectSearch } from "../../components/SelectSearch";
import { TreeSelectLocationBase } from "../../components/TreeSelectLocation";
import { useAuth } from '../../hooks/auth';
import Api, { exceptionNotificationAPI, languageGetAll } from "../../services/api";
import { ContentModal } from "./styles";

const ExportForm = ({ closeModal, baseUserLocation }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [error, setError] = useState(false);
    const [formRequired, setFormRequired] = useState(true);
    const [locationIncludeChildren, setLocationIncludeChildren] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usersDropdownLoading, setUsersDropdownLoading] = useState(false);
    const [usersDropdownOptions, setUsersDropdownOptions] = useState([]);
    const [selectedVersions, setSelectedVersions] = useState([]);

    const [formDropdownLoading, setFormDropdownLoading] = useState(false);
    const [formDropdownOptions, setFormDropdownOptions] = useState([]);

    const [formVersionDropdownLoading, setformVersionDropdownLoading] = useState(false);
    const [formVersionDropdownOptions, setformVersionDropdownOptions] = useState([]);

    const [languageDropdownLoading, setLanguageDropdownLoading] = useState(false);
    const [languageDropdownOptions, setLanguageDropdownOptions] = useState([]);

    const [locationId, setLocationId] = useState(null);
    const [locationSelected, setLocationSelected] = useState(null);

    const [formId, setFormId] = useState(null);
    const [formName, setFormName] = useState(null);

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const { user, can, } = useAuth();

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            start: dayjs().startOf("month"),
            end: dayjs().endOf("month")
        });
        loadForm();
        loadUser();
        loadLanguages();
    }, []);

    const loadLanguages = async () => {
        setLanguageDropdownLoading(true);
        var avaiableLanguages = (await languageGetAll()).data.data;
        var languageOptions = avaiableLanguages.map(lang => {
            return {
                label: lang.id,
                value: lang.id
            };
        });

        setLanguageDropdownOptions(languageOptions);
        setLanguageDropdownLoading(false);
    }

    const loadUser = async () => {
        setUsersDropdownLoading(true);
        const r = await Api.post(`/FormTask/GetUserExport`, null);
        const userOptions = r.data.response.map(i => {
            return {
                label: i.name,
                value: i.id
            };
        });
        setUsersDropdownOptions(userOptions);
        setUsersDropdownLoading(false);
    };

    const loadForm = async () => {
        setFormDropdownLoading(true);
        setformVersionDropdownLoading(true);
        setFormDropdownLoading(true);
        const r = await Api.post("/Form/getAllSelectBi", null);

        const formOptions = r.data.response.map(i => {
            return {
                label: i.title,
                value: i.id
            };
        });
        setFormDropdownOptions(formOptions);
        setFormDropdownLoading(false);
    };

    const handleSubmitForm = useCallback(async (userValues) => {
        try {
            if (userValues.translate == null) {
                userValues.translate = user.languageId;
            }
            if (formName != null) {
                userValues.formName = formName;
            }

            if (locationSelected != null) {
                userValues.locationName = locationSelected;
            }

            const sendValues = {
                FormId: userValues.formId,
                FormChilds: userValues.formVersions,
                Include: userValues.include,
                LocationId: userValues.locationId,
                LocationIncludeChildren: locationIncludeChildren,
                Start: userValues.start,
                End: userValues.end,
                TopicId: userValues.topicId,
                UserId: userValues.userId,
                Translate: userValues.translate,
                FormName: userValues.formName,
                LocationName: userValues.locationName,
                TopicName: userValues.topicName ? userValues.topicName : ""
            };
            setError(false);
            setLoading(true);
            var r = await Api.post(`/FormTask/Export`, sendValues);
            setLoading(false);
            setOpen(true)
        } catch (error) {
            exceptionNotificationAPI(error);
            setLoading(false);
        }

    });

    const handleCloseModal = () => {
        setOpen(false);
        closeModal(false);
    }

    const loadVersions = async (baseId) => {
        const selectedOption = formDropdownOptions.find(option => option.value === baseId);
        if (selectedOption) {
            setFormId(selectedOption.key)
            setFormName(selectedOption.label)
        }
        setformVersionDropdownOptions([]);
        setformVersionDropdownLoading(true);
        form.setFields([{
            name: "formVersions",
            value: []
        }]);
        if (baseId) {
            const q = await Api.get("/Form/getAllVersionsBi?Id=" + baseId);
            const formVersions = q.data.response.map(i => {
                return {
                    label: i.title,
                    value: i.id
                };
            });
            setformVersionDropdownOptions(formVersions);
            setformVersionDropdownLoading(false);
        }
    };

    return (
        <ContentModal className="font-face-gbook">
            {loading ? <Loading /> :
                <Form
                    form={form}
                    key="userform"
                    layout="vertical"
                    onFinish={handleSubmitForm}
                >
                    <Divider />
                    <Row gutter={[8, 8]}>
                        {error && <Col xs={24}>
                            <div role="alert" style={{ color: "red", fontWeight: "bold" }}>{t("Schedule.Modal.30Days")}</div>
                        </Col>}
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t("Schedule.Modal.StartDate")}
                                name="start"

                                className="ant-modal-label-name"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Required.Custom.Message3", {
                                            grammaticalPunct: "!", fieldName: (t("Schedule.Modal.StartDate").toLowerCase())
                                        })
                                    }
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    format={"YYYY/MMM/DD"}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t("Schedule.Modal.EndDate")}
                                name="end"
                                className="ant-modal-label-name"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Required.Custom.Message3", {
                                            grammaticalPunct: "!", fieldName: (t("Schedule.Modal.EndDate").toLowerCase())
                                        })
                                    }
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    format={"YYYY/MMM/DD"}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t("Schedule.Modal.Form")}
                                name="formId"
                                className="ant-modal-label-name"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Required.Custom.Message3", {
                                            grammaticalPunct: "!", fieldName: (t("Schedule.Modal.Form").toLowerCase())
                                        })
                                    }
                                ]}
                            >
                                <SelectSearch
                                    onChange={loadVersions}
                                    loading={formDropdownLoading}
                                    disabled={formDropdownLoading}
                                    optionsData={formDropdownOptions}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Form.Item
                                label={t("Schedule.Modal.Include")}
                                name="include"
                                className="ant-modal-label-name"
                            >
                                <Checkbox onChange={(e) => setFormRequired(e.target.checked)} value={formRequired} checked={formRequired} />
                            </Form.Item>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Form.Item
                                label={t("AnswerLanguage")}
                                name="translate"
                                className="ant-modal-label-name"
                                defaultValue={user.languageId}
                            >
                                <Select
                                    allowClear
                                    placeholder={t("AnswerLanguage")}
                                    className="space-search pl-15"
                                    defaultValue={user.languageId}
                                >
                                    <Option value={null}>{t("AnswerLanguage2")}</Option>
                                    {languageDropdownOptions.map((item) => {
                                        return (
                                            <Option key={item.value} value={item.value}>{item.label}</Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t("Schedule.Modal.Versions")}
                                name="formVersions"
                                className="ant-modal-label-name"
                            >
                                <TreeSelect
                                    size="middle"
                                    name="selectedFormVersions"
                                    multiple
                                    allowClear
                                    value={selectedVersions}
                                    onChange={newV => setSelectedVersions(newV)}
                                    loading={formVersionDropdownLoading}
                                    disable={formVersionDropdownLoading}
                                    treeData={formVersionDropdownOptions}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t("User.User")}
                                name="userId"
                                className="ant-modal-label-name"
                            >
                                <SelectSearch
                                    loading={usersDropdownLoading}
                                    disabled={usersDropdownLoading}
                                    optionsData={usersDropdownOptions}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t("Location")}
                                className="ant-modal-label-name"
                                name="locationId"
                            >
                                <TreeSelectLocationBase
                                    baseLocation={baseUserLocation}
                                    disabled={baseUserLocation === undefined ? true : false}
                                    size="middle"
                                    className="space-search pl-15"
                                    showPath
                                    includeDirectParents={true}
                                    onSelect={(value, node, extra) => {
                                        setLocationId(value);
                                        setLocationSelected(node.title);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t("History.LocationBelow")}
                                name="locationIncludeChildren"
                                className="ant-modal-label-name"
                            >
                                <Checkbox onChange={(e) => setLocationIncludeChildren(e.target.checked)} value={locationIncludeChildren} checked={locationIncludeChildren} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Button key="back" onClick={() => {
                                closeModal(false);
                            }} className="font-face-gbold">{t("BtnCancel")}</Button>
                            <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 8 }}
                                className="font-face-gbold">{t("Schedule.Modal.RequestReport")}</Button>
                        </Col>
                    </Row>
                </Form>
            }
            <Modal
                title={t("Schedule.Modal.ExportSucessTitle")}
                visible={open}
                onOk={handleCloseModal}
                confirmLoading={confirmLoading}
                closable={false}
                footer={[
                    <Button key="ok" type="primary" onClick={handleCloseModal}>
                        OK
                    </Button>
                ]}
            >
                <p>{t("Schedule.Modal.ExportSucessMessage")}</p>
            </Modal>
        </ContentModal>
    );
};

export default ExportForm;