import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    @font-face {
        font-family: Nunito;
    }

    .no-border .ql-snow {
        border: none !important;
    }

    .font-face-gbold {
        font-family: Nunito;
        font-weight: 500;
    }

    .font-face-gbook {
        font-family: Nunito;
    }

    .losang {
        transform: skew(5deg);
    }

    .ant-upload-disabled {
        display: none !important;
    }

    .expand .ant-upload-list {
        display: block !important;
    }

    .items-1-per-line .ant-upload-list-item-container {
        width: 100% !important;
        height: 100% !important;
        margin-top: 5px !important;
    }

    .items-2-per-line .ant-upload-list-item-container {
        width: 50%!important;
        height: 100% !important;
        margin-top: 5px !important;
    }

    .items-3-per-line .ant-upload-list-item-container {
        width: 33.33% !important;
        height: 100% !important;
        margin-top: 5px !important;
    }

    .items-4-per-line .ant-upload-list-item-container {
        width: 25% !important;
        height: 100% !important;
        margin-top: 5px !important;
    }

    .losang > span, .losang .workflow-detail-helper {
        background-color: #afafaf !important;
        color: #fff !important;
    }

    .start > span, .start .workflow-detail-helper {
        background-color: #64ab64 !important;
        color: #fff !important;
    }

    .finish > span, .finish .workflow-detail-helper {
        background-color: #c52b2b !important;
        color: #fff !important;
    }

    html {
        scroll-behavior: smooth;
    }

    :root {
        --color-background: #F8F8F8;
        --color-section: #EEEEEE;
        --blue: #4085c6;
        --blue-title: #4085c6;
        --sidebar-background: #4085c6;
        --sidebar-text-color: #FFF;
        --sidebar-option-selected: #32639e;
        --sidebar-subMenuItems-background: #1669b6; 
        --sidebar-subMenuItems-border-color: #6CBDFF; 
    }
    @primary-color: #4085c6;
 
    .workflow-detail .hide-buttons-activity {
        display: none !important;
    }
    
    .disabled-cells .Spreadsheet__cell,
    .Spreadsheet__cell--readonly div,
    .Spreadsheet__cell--readonly {
        background-color: rgb(248 248 248)  !important;
        outline-color: rgb(248 248 248)  !important;
        --outline-color: rgb(248 248 248)  !important;
        color: rgb(0 0 0 / 55%) !important;
    }

    .ant-form-item-has-error .Spreadsheet {
        border: 1px solid red;
        border-radius: 3px;
    }

    .ant-input[disabled] {
        color: rgb(0 0 0 / 55%) !important;
    }

    .ant-select-selection-placeholder, .ant-picker-input input::placeholder {
        color: rgb(0 0 0 / 40%) !important;
    }

    //ED: Não faz falta e permite impressão de form com mais de uma página.
    html, body {
    //    overflow-x: hidden !important;
        font-family: Nunito !important;
    }

    .form-answer .ant-form-item {
        margin-bottom: 6px !important;
    }

    .form-answer .ant-form-item-label{
        padding: 0 0 2px !important;
    }

    .form-answer .ant-card-body {
        padding: 18px 18px 14px 18px !important;
    }

    .form-answer .ant-card {
        margin-bottom: 15px !important;
    }
    
    .form-answer .ant-divider-horizontal {
        margin: 14px 0 !important;
    }

    ::-webkit-scrollbar {
        width: 4px; /* Largura da barra de rolagem vertical */
        height: 4px; /* Altura da barra de rolagem horizontal */
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #f1f1f1; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #c2c2c2; 
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: #c2c2c2; 
    }
    
    .card-p-10 {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      opacity: 1;
      .ant-card-body{
            height: 100%;
            padding: 10px !important;
      }
      .ant-col{
        color: #000000 !important;
        font-weight: 400;
      }
    }
    .ant-layout-sider-children{
        overflow-y: auto;
        overflow-x: hidden;
    }
    .mt-10 {
        margin-top: 10px;
    }
    //card header style
    .card-container {
      background: #F8F8F8;
      border: none;
      margin-bottom: 10px;

      .col-header{
        margin-top: 5px; 
        display: flex; 
        align-items: center;
        cursor: pointer;
      }

      .ant-card-body{
        height: 100%;
        padding: 10px !important;
        box-shadow: none;
      }

    .ant-col, span {
        display: flex;
        align-items: center;
    }      

    .header-title{
        color: #929292;
        text-align: left;
        opacity: 1;
      }

      .svg-sort{
        color: #929292;
        font-size: 15px;
      }

      .svg-sort-active{
        margin-top: 5px; 
        font-size: 15px;
        color: #4890FF;
      }
    }
    .card-p-10:hover{
        transition: 0.4s;
        border-left: 5px solid #4085c6 !important;
    }
    
    .ant-form-item input[type='radio'], .ant-form-item input[type='checkbox'] {
        width: 16px !important;
        height: 16px !important;
    }
    .ant-checkbox-group-item{
        margin-top: 8px;
    }
    .card-primary {
        .ant-card-head{
            background-color: #4085c6;
            color: #fff;
            line-height: 1.5715;
            font-size: 14px;
            background: #4085c6 0% 0% no-repeat padding-box;
            border-radius: 8px 8px 0 0;
            opacity: 1;
        }
        .is-linked .ant-card-head{
            background-color: rgb(68 68 68);
        }
        .ant-card-head-title {
            padding: 9px 0  !important;
        }
        .ant-card{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            opacity: 1;
        }
    }
    .ant-tag{
        border-radius: 6px !important;
    }
    .ant-card-bordered {
        border: 0.5px solid #ffffff61 !important;
    }
    .ant-form-item-control-input-content input, .ant-input-group-wrapper {
        border-radius: 6px !important;
        opacity: 1;
    }
    .ant-collapse {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
    }
    .ant-btn {
        opacity: 1;
        text-align: center;
    }
    .ant-popover.ant-popconfirm .ant-popover-placement-top {
      z-index: 9999 !important;
    }
    
    .ant-btn-primary {
        color: #fff !important;
        background: #4085c6 0% 0% no-repeat padding-box !important;
        border-color: #4085c6 !important;
    }
    .ant-btn-default {
        border: 1px solid #4085c6 !important;
        color: #4085c6;
    }
    .ant-card-body-12 .ant-card-body{
        padding: 12px !important;
    }
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
        padding: 4px 16px !important;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        content: '';
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        content: '*';
    }
    h4.ant-typography, .ant-typography h4{
        font-size: 20px;
        font-weight: 700;
    }
    .section-item .ant-form-item {
        margin-bottom: 2px !important;
    }
    .row-top{
        position: sticky;
        top: 0;
        height: 60px;
        background-color: #fff;
        padding: 12px;
        width: 100%;
    }

    .input-small-ant {
        .ant-input, .ant-input-number, .ant-picker, .ant-select > .ant-select-selector{
            padding: 4px !important;
            border-radius: 6px;
            font-weight: 500;
            height: 40px;
        }
        .ant-card-body {
            padding-top: 10px !important;
            padding-bottom: 5px !important;
        }
    }

    .ant-modal-body .ant-select-selector{
        min-height: 40px !important;
        .ant-select-selection-search {
            padding-top: 3px;
        }
        .ant-select-selection-item {
            padding-top: 3px;
        }
        .ant-select-selection-placeholder {
            padding-top: 3px;
        }
    }
    .ant-modal-body {
        .ant-form-item-control-input > div > input {
            min-height: 40px !important;
            border: 1px solid #d9d9d9;
        }
        .ant-input-password > input {
            min-height: 30px !important;
        }
    }

    .text-center {
        text-align: center;
    }
    .text-right {
        text-align: right;
    }
    .self-center {
        align-self: center;
    }
    .items-center {
        align-items: center;
    }
    .justify-content-end {
        justify-content: flex-end;
    }
    .justify-self-end {
        justify-self: flex-end;
    }

    .ant-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .btn-show-modal {
      min-height: 3.8025vh;
      min-width:  6.8541VW;
    }

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    *.focus {
        outline: 0;
    }
    
    html, body, #root{
        height: 100%
    }
    
    body{
        -webkit-font-smoothing: antialiased !important;
        background-color: #f8f8f8;
    }

    body, input, button {
        font-size: 14px;
    }

    a {
        text-decoration: none;
    }

    ul {
        list-style: none;
    }
    
    button{
      cursor: pointer;
    }

    .ant-btn-link span {
        color: #4085c6 !important;
    }

    .ant-menu-item.menu-item:hover, .ant-menu-item:hover::after, .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
        border: none !important; 
        border-bottom-width: 0px !important;
        border-bottom-color: #fff !important;
    }

    header.ant-layout-header.site-layout-background {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .1);
        height: 60px;
        line-height: 60px;
        position: relative;
        z-index: 99;
    }

    .ant-layout-header > ul {
        border: 0;
        height: 60px;
        display: flex;
        align-items: center;
    }
  
    footer {
        text-align: center;
    }
`;
