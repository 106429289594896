//Consts default do backend
/** @enum {number} */
export const FORM_STATUS = { //TODO: update to the Newer definition => import { DocumentStatus } from "../../interfaces/IForm";
  Editing: 0,
  Active: 1,
  OldVer: 2,
  OldVerPub: 3,
  Template: 4,
  Approval: 5
};
export const FORM_STATUS_DISPLAY = { //TODO: update to the Newer definition => import { DocumentStatus } from "../../interfaces/IForm";
  Editing: 0,
  Active: 1
};

export const TEMPLATE_STATUS = {
  Template: 4,
  Editing: 0
}

/** @enum {number} */
export const TASK_STATUS = {
  Pending: 0,
  Late: 1,
  Open: 2,
  Done: 3,
  Recurrency: 5,
  Approval: 6
};

/** @enum {number} */
export const ORIGIN_STATUS = {
  Created: 0,
  Published: 1,
  OldVer: 2,
  OldVerPub: 3,
};

/** @enum {string} */
export const TASK_STATUS_STRING = {
  Pending: "Pending",
  Late: "Late",
  Open: "Open",
  Approval: "Approval",
  Done: "Done",
  Recurrency: "Recurrency",
};

/** @enum {number} */
export const PAGE_TYPE = {
  EDIT: 0,
  APLY: 1,
  VIEW: 2,
}

/** @enum {number} */
export const ENTITY_TYPE = {
  FORM: 0,
  LOCATION: 1,
  TOPIC: 2,
  LIST: 3,
  LIST_TREE: 4
}

/** @enum {number} */
export const PANEL_ORIGIN = {
  TRANSLATE: 0,
  TEMPLATE: 1,
  EDIT: 2
}