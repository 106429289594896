import { originItemsGetAll, getTopics, topicGetAllSimple, locationGetAllSimple, treeListGetAll, treeListItemsGetAll, languageGetAll, getLocationByCompanyId, getAllTopicByUser, getTopicsByProfile } from "../services/api";

const removeRedundantDataFromApi = (apiData) => {
    let preProcessedData = [];
    // Clean redundant data from the API response and keep the Tree Data Structure
    // TODO: This can be a performance issue in the future!
    for (const i of apiData) {
        if (i.parentId === null) {
            preProcessedData.push(i);
        }
    }

    if (preProcessedData.length == 0)
        return apiData;

    return preProcessedData;
}

/**
 * @typedef {Object} treeNode
 * @property {string} name
 * @property {string} value
 * @property {treeNode[]} children
 * @returns {treeNode[]}
 */
const genericBuildTreeRecursive = (apiData) => {
    let preProcessedData = removeRedundantDataFromApi(apiData);

    let tree = recursiveBuildTree(preProcessedData);

    function recursiveBuildTree(items) {
        const childrenArr = []
        for (const i of items) {
            childrenArr.push(
                {
                    name: i.name,
                    value: i.id,
                    children: recursiveBuildTree(i.children)
                }
            )
        }

        return childrenArr;
    }

    return tree;
}

export const parseToAntDTree = (apiData, disabledNodes = [], disableWholeTree = true, shouldRemoveRedundand = true, isFlat = false) => {
    //This function returns a very specific dataStructure that AntD framework understand as a tree from TreeSelect component
    let preProcessedData = apiData;
    if (shouldRemoveRedundand) {
        preProcessedData = removeRedundantDataFromApi(apiData);
    }

    let tree;
    if (isFlat) {
        function recursiveBuildTree(items, antTree, isDisabled, parent) {
            for (const i of items) {
                const nodeDisabled = disabledNodes.includes(i.id);
                let disableChild = false;
                if ((nodeDisabled || isDisabled) && disableWholeTree) {
                    disableChild = true;
                }
                const newNode = {
                    id: i.id,
                    pId: parent?.id,
                    title: i.name,
                    value: i.id,
                    disabled: nodeDisabled || isDisabled,
                    parent: parent,
                    code: i.code
                }
                antTree.push(newNode);
                recursiveBuildTree(i.children, antTree, disableChild, newNode);
            }
        }

        tree = [];
        recursiveBuildTree(preProcessedData, tree, false);
    }
    else {
        tree = recursiveBuildTree(preProcessedData, false);

        function recursiveBuildTree(items, isDisabled) {
            const childrenArr = []
            for (const i of items) {
                const nodeDisabled = disabledNodes.includes(i.id);
                let disableChild = false;
                if ((nodeDisabled || isDisabled) && disableWholeTree) {
                    disableChild = true;
                }
                childrenArr.push(
                    {
                        title: i.name,
                        value: i.id,
                        children: recursiveBuildTree(i.children, disableChild),
                        disabled: nodeDisabled || isDisabled
                    }
                )
            }

            return childrenArr;
        }
    }

    return tree;
};

export const parseToAntDSelectOptions = (apiData) => {
    const options = apiData.map(option => {
        return {
            name: option.name, // @Deprecated property: use label instead
            value: option.id,
            label: option.name,
        };
    });
    return options;
};

export const getLocationsAsTree = async (searchText = "") => {
    const r = await locationGetAllSimple();
    return genericBuildTreeRecursive(r.data.response.items);
}

export const getTopicsAsTree = async () => {
    const r = await getTopicsByProfile(); 
    return genericBuildTreeRecursive(r.data.response);
}

export const getLanguages = async (searchText = "") => {
    const r = await languageGetAll();
    var options = parseToAntDSelectOptions(r.data.data);
    return options;
}

export const getLocationsAsAntDTree = async (disabledNodes=[], disableWholeTree=true, isFlat = false) => {
    const r = await locationGetAllSimple();
    return parseToAntDTree(r.data.response.items, disabledNodes, disableWholeTree, true, isFlat);
}

export const getLocationsByCompanyAsAntDTree = async (disabledNodes = [], disableWholeTree = true, isFlat = false) => {
    const r = await getLocationByCompanyId();
    return parseToAntDTree([r.data.response], disabledNodes, disableWholeTree, false, isFlat);
}

export const getTreelistDataAsSelect = async (searchLocation = "", searchTopic = "") => {
    const r = await treeListGetAll(searchLocation, searchTopic);
    return parseToAntDSelectOptions(r.data.response.items);
}

export const getTreeListAsAntDTree = async (ListTreeId = "", disabledNodes = [], disableWholeTree = true) => {
    const r = await treeListItemsGetAll(ListTreeId);
    return parseToAntDTree(r.data.response.items, disabledNodes, disableWholeTree);
}

export const getOriginAsAntDTree = async (OriginInformationId = "", disabledNodes = [], disableWholeTree = true) => {
    const r = await originItemsGetAll(OriginInformationId);
    return parseToAntDTree(r.data.response, disabledNodes, disableWholeTree);
}

export const getTopicAsAntDTree = async (disabledNodes = [], disableWholeTree = true) => {
    const r = await getTopicsByProfile();
    return parseToAntDTree(r.data.response, disabledNodes, disableWholeTree);
}

export const getUserTopicAsAntDTree = async (disabledNodes = [], disableWholeTree = true) => {
    const r = await getTopics();
    return parseToAntDTree(r.data.response, disabledNodes, disableWholeTree);
}

export const getTopicSimple = async () => {
    const r = await topicGetAllSimple();
    return r;
}

export const getTranslationFromObj = (obj, languageId) => {
    let translation = obj?.translations?.find(t => t.languageId === languageId);
    return translation;
}

/**
 * 
 * @param {str} string 
 * @param {int} maxLimit 
 * @param {int} cutString 
 * @returns 
 */
export const truncateString = (string = null, maxLimit = 150) => {
    if (string == null) {
        return false;
    }

    if (string.length >= maxLimit) {
        return string.substr(0, maxLimit) + '...';
    }

    return string;
}