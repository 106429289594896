import { lazy, Suspense, useEffect, useMemo } from 'react';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import { Loading } from '../components/Loading';
import PageNotFound from '../components/PageNotFound';
import { useAuth } from '../hooks/auth';
import AuthLayout from "../pages/_layouts/Authorized";
import NotAuthLayout from "../pages/_layouts/NotAuthorized";
import RouteWrapper from './route';

import Home from '../pages/Home';
import SignIn from '../pages/SignIn';

// Lazy loading das páginas
const Administration = lazy(() => import('../pages/Administration'));
const Anonymous = lazy(() => import('../pages/Anonymous'));
const ChangeLog = lazy(() => import('../pages/ChangeLog'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const UserConfirmResetPassword = lazy(() => import('../pages/ChangePassword/resetPassword'));
const UserRequestResetPassword = lazy(() => import('../pages/ChangePassword/sendResetPassword'));
const DocumentControlIndex = lazy(() => import('../pages/DocumentControl'));
const DocumentControlDetails = lazy(() => import('../pages/DocumentControl/details'));
const DocumentControlDocShare = lazy(() => import('../pages/DocumentControl/docShare'));
const DocumentControlEditSimple = lazy(() => import('../pages/DocumentControl/editsimple'));
const DocumentControlLog = lazy(() => import('../pages/DocumentControl/log'));
const DocumentControlNew = lazy(() => import('../pages/DocumentControl/new'));
const DocumentControlShare = lazy(() => import('../pages/DocumentControl/share'));
const DocumentControlTrained = lazy(() => import('../pages/DocumentControl/trained'));
const Word = lazy(() => import('../pages/DocumentControl/word'));
const DocumentTypeIndex = lazy(() => import('../pages/DocumentType'));
const FormBuilderApply = lazy(() => import('../pages/FormBuilder/Apply'));
const FormBuilder = lazy(() => import('../pages/FormBuilder/form'));
const FormBuilderIndex = lazy(() => import('../pages/FormBuilder/list'));
const FormBuilderDetails = lazy(() => import('../pages/FormBuilder/list/details'));
const FormBuilderLog = lazy(() => import('../pages/FormBuilder/list/log'));
const FormBuilderViewAnswer = lazy(() => import('../pages/FormBuilder/ViewAnswer'));
const FormTranslate = lazy(() => import('../pages/FormTranslate/form'));
const FormBuilderApplyLog = lazy(() => import('../pages/Home/log'));
const ListIndex = lazy(() => import('../pages/List'));
const ListConfig = lazy(() => import('../pages/List/ConfigNode'));
const LocationIndex = lazy(() => import('../pages/Location'));
const FormLocationTranslate = lazy(() => import('../pages/Location/formTranslate'));
const OriginInformationIndex = lazy(() => import("../pages/OriginInformation"));
const OriginInformationConfig = lazy(() => import("../pages/OriginInformation/ConfigNode"));
const PowerBI = lazy(() => import('../pages/PowerBI'));
const HomeCustom = lazy(() => import('../pages/PowerBI/indexCustom'));
const PowerBIList = lazy(() => import('../pages/PowerBI/list'));
const Reports = lazy(() => import('../pages/Reports'));
const Roadmaps = lazy(() => import('../pages/Roadmap'));
const TenantIndex = lazy(() => import('../pages/Tenant'));
const TopicIndex = lazy(() => import('../pages/Topic'));
const TopicTranslate = lazy(() => import('../pages/Topic/formTranslate'));
const TreelistIndex = lazy(() => import('../pages/TreeList'));
const TreelistConfig = lazy(() => import('../pages/TreeList/ConfigNode'));
const FormTranslateTreeList = lazy(() => import('../pages/TreeList/formTranslate'));
const UserIndex = lazy(() => import('../pages/Users'));
const Wiki = lazy(() => import('../pages/Wiki'));
const Workflow = lazy(() => import('../pages/Workflow'));
// const WorkflowDesignerCreate = lazy(() => import('../pages/Workflow/WorkflowCreate'));
// const WorkflowDetail = lazy(() => import('../pages/Workflow/WorkflowDetail'));
// const WorkflowDesignerEdit = lazy(() => import('../pages/Workflow/workflowEdit'));

const RoutesList = () => {
    const { UserPermissionAccess, user } = useAuth();

    // Memoizando `hasPermission` para evitar recalculações desnecessárias
    const hasPermission = useMemo(() => UserPermissionAccess(), [UserPermissionAccess]);

    const ScrollToTop = ({ children }) => {
        const location = useLocation();

        useEffect(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, [location.pathname]);

        return children;
    };

    const LayoutWithSuspense = useMemo(
        () => ({ children, isPublic }) => {
            const Layout = isPublic || (user && user.userName === "anonymous") ? NotAuthLayout : user ? AuthLayout : NotAuthLayout;

            return (
                <Layout>
                    <Suspense fallback={<Loading width="95%" />}>
                        <ScrollToTop>{children}</ScrollToTop>
                    </Suspense>
                </Layout>
            );
        },
        [user] // Dependendo do estado `user`, o layout será reavaliado
    );

    const router = useMemo(
        () =>
            createBrowserRouter([
                {
                    path: "/an/:tenant/:token", element: (
                        <NotAuthLayout>
                            <Suspense fallback={<Loading width="95%" />}>
                                <ScrollToTop>
                                    <RouteWrapper element={Anonymous} isPublic />
                                </ScrollToTop>
                            </Suspense>
                        </NotAuthLayout>
                    ),
                },
                { path: "/", element: <LayoutWithSuspense isPublic={true}><RouteWrapper element={SignIn} /></LayoutWithSuspense> },
                { path: "/home", element: <LayoutWithSuspense><RouteWrapper element={Home} isPrivate /></LayoutWithSuspense> },
                { path: "/home/custom", element: <LayoutWithSuspense><RouteWrapper element={HomeCustom} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/changePassword", element: <LayoutWithSuspense><RouteWrapper element={ChangePassword} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/powerbi/", element: <LayoutWithSuspense><RouteWrapper element={PowerBIList} userHasPermission={hasPermission.powerbi} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/powerbi/view/:id/:isCustom?", element: <LayoutWithSuspense><RouteWrapper element={PowerBI} userHasPermission={hasPermission.powerbi} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/workflow", element: <LayoutWithSuspense><RouteWrapper element={Workflow} userHasPermission={hasPermission.access} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder/create", element: <LayoutWithSuspense><RouteWrapper element={FormBuilder} userHasPermission={hasPermission.formbuilder} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder/edit/:id", element: <LayoutWithSuspense><RouteWrapper element={FormBuilder} userHasPermission={hasPermission.formbuilder} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder/log/:id", element: <LayoutWithSuspense><RouteWrapper element={FormBuilderLog} userHasPermission={hasPermission.formbuilder} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder/details/:id", element: <LayoutWithSuspense><RouteWrapper element={FormBuilderDetails} userHasPermission={hasPermission.formbuilder} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder/task/log/:id", element: <LayoutWithSuspense><RouteWrapper element={FormBuilderApplyLog} userHasPermission={hasPermission.formtask} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder/view/:id", element: <LayoutWithSuspense><RouteWrapper element={FormBuilderApply} userHasPermission={hasPermission.formtask} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder/apply/:id/:taskId?", element: <LayoutWithSuspense><RouteWrapper element={FormBuilderApply} userHasPermission={hasPermission.formtask} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder/recurrency/:recurrencyId/:recurrencyData/:recurrencyWeek", element: <LayoutWithSuspense><RouteWrapper element={FormBuilderApply} userHasPermission={hasPermission.formtask} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder/view-answer/:id", element: <LayoutWithSuspense><RouteWrapper element={FormBuilderViewAnswer} userHasPermission={hasPermission.formtask || hasPermission.formbuilder} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder", element: <LayoutWithSuspense><RouteWrapper element={FormBuilderIndex} userHasPermission={hasPermission.formtask || hasPermission.formbuilder} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/tenant", element: <LayoutWithSuspense><RouteWrapper element={TenantIndex} userHasPermission={hasPermission.tenant} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/topic", element: <LayoutWithSuspense><RouteWrapper element={TopicIndex} userHasPermission={hasPermission.topic} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/topic/translate/:originLanguageId/:destLanguageId", element: <LayoutWithSuspense><RouteWrapper element={TopicTranslate} userHasPermission={hasPermission.topic} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/doctype", element: <LayoutWithSuspense><RouteWrapper element={DocumentTypeIndex} userHasPermission={hasPermission.documenttype} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/user", element: <LayoutWithSuspense><RouteWrapper element={UserIndex} userHasPermission={hasPermission.user} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/location", element: <LayoutWithSuspense><RouteWrapper element={LocationIndex} userHasPermission={hasPermission.location} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/location/translate/:originLanguageId/:destLanguageId", element: <LayoutWithSuspense><RouteWrapper element={FormLocationTranslate} userHasPermission={hasPermission.formbuilder} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/formbuilder/translate/:id/:originLanguageId/:destLanguageId", element: <LayoutWithSuspense><RouteWrapper element={FormTranslate} userHasPermission={hasPermission.formbuilder} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/documentcontrol", element: <LayoutWithSuspense><RouteWrapper element={DocumentControlIndex} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/documentcontrol/share/:id?", element: <LayoutWithSuspense><RouteWrapper element={DocumentControlShare} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/documentcontrol/trained/:id?", element: <LayoutWithSuspense><RouteWrapper element={DocumentControlTrained} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/documentcontrol/create", element: <LayoutWithSuspense><RouteWrapper element={DocumentControlNew} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/documentcontrol/edit/:id", element: <LayoutWithSuspense><RouteWrapper element={DocumentControlNew} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/documentcontrol/editsimple/:id", element: <LayoutWithSuspense><RouteWrapper element={DocumentControlEditSimple} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/documentcontrol/edit/:id/:newVersion", element: <LayoutWithSuspense><RouteWrapper element={DocumentControlNew} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/documentcontrol/details/:id", element: <LayoutWithSuspense><RouteWrapper element={DocumentControlDetails} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/documentcontrol/log/:id", element: <LayoutWithSuspense><RouteWrapper element={DocumentControlLog} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/documentcontrol/word", element: <LayoutWithSuspense><RouteWrapper element={Word} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/list", element: <LayoutWithSuspense><RouteWrapper element={ListIndex} userHasPermission={hasPermission.list} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/list/items/:id", element: <LayoutWithSuspense><RouteWrapper element={ListConfig} userHasPermission={hasPermission.list} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/reports", element: <LayoutWithSuspense><RouteWrapper element={Reports} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/administration", element: <LayoutWithSuspense><RouteWrapper element={Administration} userHasPermission={hasPermission.role} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/origininformation", element: <LayoutWithSuspense><RouteWrapper element={OriginInformationIndex} userHasPermission={hasPermission.origininformation} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/origininformation/items/:id", element: <LayoutWithSuspense><RouteWrapper element={OriginInformationConfig} userHasPermission={hasPermission.origininformation} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/treelist", element: <LayoutWithSuspense><RouteWrapper element={TreelistIndex} userHasPermission={hasPermission.treelist} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/treelist/items/:id", element: <LayoutWithSuspense><RouteWrapper element={TreelistConfig} userHasPermission={hasPermission.treelist} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/treelist/items/edit/:id", element: <LayoutWithSuspense><RouteWrapper element={TreelistConfig} userHasPermission={hasPermission.treelist} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/treelist/translate/:id/:originLanguageId/:destLanguageId", element: <LayoutWithSuspense><RouteWrapper element={FormTranslateTreeList} userHasPermission={hasPermission.treelist} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/changelog", element: <LayoutWithSuspense><RouteWrapper element={ChangeLog} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/roadmap", element: <LayoutWithSuspense><RouteWrapper element={Roadmaps} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/wiki", element: <LayoutWithSuspense><RouteWrapper element={Wiki} isPrivate /></LayoutWithSuspense>, isPrivate: true },
                { path: "/docshare/:tenant/:id", element: <LayoutWithSuspense isPublic={true}><RouteWrapper element={DocumentControlDocShare} isPublic /></LayoutWithSuspense>, isPublic: true },
                { path: "/user/send-reset-password", element: <LayoutWithSuspense isPublic={true}><RouteWrapper element={UserRequestResetPassword} isPublic /></LayoutWithSuspense>, isPublic: true },
                { path: "/user/reset-password", element: <LayoutWithSuspense isPublic={true}><RouteWrapper element={UserConfirmResetPassword} isPublic /></LayoutWithSuspense>, isPublic: true },
                { path: "/:tenant", element: <LayoutWithSuspense isPublic={true}><RouteWrapper element={SignIn} isPublic /></LayoutWithSuspense>, isPublic: true },
                { path: "*", element: <LayoutWithSuspense isPublic={true}><RouteWrapper element={PageNotFound} isPrivate /></LayoutWithSuspense>, isPrivate: true }
            ]),
        [hasPermission]
    );

    return <RouterProvider router={router} />;
};

export default RoutesList;
