import styled from 'styled-components';
import { Button } from 'antd';

export const ContentModal = styled.div`
   
    .ant-modal-label-name{
        font-weight: bold;
    }
`;

export const MessageInfo = styled.div`
   color: #4085c6; 
   margin-left: 5px;
   margin-bottom: 20px;
`;

export const LoadingApply = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  top: 0;
  left: 0;
  background-color: #f8f8f8;

  .loading-spinner-apply {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    
    .ant-spin-spinning {
      margin: 0 auto;
      display: block;
    }
  }
`;


export const ContainerCard = styled.div`
  background-color: '#FFF';
  padding: 2%;
  opacity: 1;

  .legend-card {
    color: #929292;
  }

  .space {
    margin-bottom: 20px;
  }

  .ant-divider-horizontal {
    margin: 15px 0;
    width: 100%;
  }

  .formbuilder-card-box {
    background: #ffffff;

    .ant-card-head-title {
      letter-spacing: 1.2;
      font-weight: bold;
      font-size: 18;
      /* color: #FFFFFF;
            border-radius: 0 0 2px 2px;
            background: #4085c6; */
    }

    .anticon-setting {
      color: #000000;
    }

    &.apply-collapse {
      .ant-collapse-header {
        font-size: 1rem;
        font-weight: 700;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .ant-collapse-item {
        border: 0;
      }
      .ant-collapse-arrow {
        top: 10.001px;
      }
      .ant-collapse-content{
        padding: 10px;
      }
      .row-pb15 {
        margin-bottom: 15px;
      }
    }
  }

  .field-row-preview {
    margin-bottom: 6px;
    opacity: 1;
  }
`;

export const Container = styled.div`
    height: 100%;
    margin-top: 0; 
    padding: 15px 32px 0 32px;

    .new-card-history {
      transition: width 2s;
    }
    
    .ant-statistic-title {
        margin-bottom: 0px;
    }

    .ant-statistic-content {
        font-size: 20px;
    }

    .card-ml-50 {
        margin-left: 50px !important;
    }
    
    .new-card-history-workflow-done {
        border-left: 5px solid #67d181 !important;
    }

    .new-card-history-workflow-open {
        border-left: 5px solid rgb(43, 87, 154) !important;
    }

    .new-card-history-workflow-late {
        border-left: 5px solid rgb(255, 77, 79) !important;
    }

    .new-card-history .ant-card-body {
        padding: 10px !important;
    }

    .new-card-history .ant-card-actions > li {
        margin: 6px 0 !important;
    }

    small {
      font-size: 85%;
    }

    .ant-table-tbody > tr > td {
        word-break: break-word !important;
        white-space: normal !important;
    }

    .header-formbuilder-list{
        display: flex;
        margin-left: 20; 
        justify-content: space-between; 
        width: 100%;
        
        .btn-formbuilder-list{
            float: right; 
            margin-right: 20;

            .btn-export {
                color: #40A9FF;
                border: 1px solid #40A9FF;
            }
        }
       
    }

    .icon-EBB41D {
        color: #EBB41D;
    }
    .text-929292 {
        color: #929292;
    }
    .text-right {
        text-align: right !important;
    }
    .d-flex {
        display: flex;
    }
    .strong-30 {
        font-size: 30px;
    }
    .pt-4 {
        padding-top: 4px;
    }
    .pt-5 {
        padding-top: 5px;
    }
    .pt-6 {
        padding-top: 6px;
    }
    .pt-7 {
        padding-top: 7px;
    }
    .pt-10 {
        padding-top: 10px;
    }
    .pt-15 {
        padding-top: 15px;
    }
    .pt-30 {
        padding-top: 30px;
    }
    .ant-card-body {
        padding: 20px;
        border-radius: 6px;
    }
    .ant-tag {
        margin: 2px !important;
    }
    .text-166DCE {
        color: #4085c6;
    }
    .text-C52B2B {
        color: #C52B2B;
    }
    .tag-F9D9D9 {
        background: #F9D9D9 0% 0% no-repeat padding-box;
        border-radius: 2px;
        opacity: 1;
    }
    .tag-CFE2F8 {
        background: #CFE2F8 0% 0% no-repeat padding-box;
        border-radius: 2px;
        opacity: 1;
    }
    .info-dash {
        display: flow-root;
        text-align: center;
    }
    .background-166DCE{
        background-color: #4085c6;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        border:2px solid #eeeeee;
    }
    .background-757575{
        background-color: #757575;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        border:2px solid #eeeeee;
    }
    .background-32AEA1{
        background-color: #32AEA1;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        border:2px solid #eeeeee;
    }

    //card header style
    .card-container {
      background: #F8F8F8;
      border: none;
      margin-bottom: 10px;

      .col-header{
        margin-top: 5px; 
        display: flex; 
        align-items: center;
        cursor: pointer;
      }

      .ant-card-body{
        height: 100%;
        padding: 10px !important;
        box-shadow: none;
      }

      .header-title{
        color: #929292;
        text-align: left;
        opacity: 1;
      }

      .svg-sort{
        color: #929292;
        font-size: 15px;
      }

      .svg-sort-active{
        margin-top: 5px; 
        font-size: 15px;
        color: #4890FF;
      }
    }


`;
export const ContentPanelIndex = styled.div`
    background-color: #F8F8F8; 
    opacity: 1;
    width: 100%;
    box-shadow: none;

    
    .ant-table-column-sorters > span{
        color: #929292; 
        opacity: 1;
    }

    .ant-tabs-tab {
        margin: 0;
        padding: 0 19px;

        .ant-tabs-tab-btn {
            padding: 12px 0;
            font-weight: 300;
            font-size: 20px;
            color: #929292;
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-weight: 500;
        color: #000000;
        text-shadow: 0 0 0.25px currentColor;
    }
    
    .ant-divider-horizontal {
        margin: 15px 0;
        width: 100%;
    }
    
    .formbuilder-card-box{
        background: #FFFFFF;

        .ant-card-head-title{
            letter-spacing: 1.2; 
            font-weight: bold;
            font-size: 18;
        }

        .anticon-setting{
            color: #000000;
        }
    }

    .row-tab-text {
        padding-left: 16px;

        .tab-text{
            font-size: 12px;
            opacity: 1;
        }
    }

    .ant-collapse-box{
        overflow-y: hidden;
    }

    .panel-content {
        letter-spacing: 1.2; 
        font-weight: bold;
    }

    .search-icon {
        font-size: 16px;
        color: #929292;
        opacity: 1;
    }

    .scrollbar-tab-pane{
        width: 300px;
    }

    .collapse-panels{
        margin-top: 20px;
        width: 280px;
    }

    .space-custom-header {
        display: flex;  
        justify-content: space-between;
        width: 300px;
        border: 1px solid blue;
        
        
        .icon-header {
            font-size: 20px;
            color: #929292;
            opacity: 1;
            cursor: pointer;
         }

        .title-header{
            opacity: 1; 
        }
    }

    .more-outlined{
        font-size: 20px;
        color: #929292;
    }

    .container-space{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .float-left{
        float: left;
    }

    .table{
        margin-top: 30px;
    }

    .tag-columns{
        width: 96px;
        margin: auto !important;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;
    }

    .table-footer-legend{
        /* border: 1px solid red; */
        margin-bottom: 20px;
        text-align: center;
        color: #C52B2B;
        opacity: 1;

        a{
            color: #C52B2B;
        }

    }

    .hidden-text-overflow{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
    }   

    .space-search{
        width: 100%;
    }

    .pl-15{
        padding-left: 15px !important;
    }

    .ml-15{
       margin-left: 0px !important;
    }

`;

export const FullCalendarContainer = styled.div`
    .fc-theme-standard td, .fc-theme-standard th {
        border: none !important;
        border-bottom: 1px solid #dddddd90 !important;
    }
    .fc-theme-standard .fc-scrollgrid {
        border: none !important;
    }

    & {
        .event-text {
            color: #fff;
            overflow: hidden;
        }

        .footer-container {
            margin-top: 15px;
        }
    }
    & .fc {
        .fc-none {
            display: none;
        }
        .fc-popover-body {
            max-height: 200px;
            overflow-y: scroll;
        }
        .fc-toolbar.fc-header-toolbar{
            margin-bottom: 10px !important;
        }
        .fc-daygrid-day-events{
            height: 69px;
            max-height: 69px;
            overflow-y: auto;
        }
        @media only screen and (max-width: 1367px) {
            .fc-daygrid-day-events{
                height: 40px;
                max-height: 40px;
            }
        }
        .fc-icon {
            font-size: 15px;
        }
        .fc-toolbar-title {
            color: rgba(0, 0, 0, 0.45) !important;
            font-size: 14px;
            font-weight: 500;
        }
        .fc-col-header-cell-cushion {
            color: rgba(0, 0, 0, 0.85) !important;
            font-weight: 500;
        }

        .fc-button-primary
        {
            color: rgba(0, 0, 0, 0.85);
            margin: 2px;
            background: transparent;
            border: none;
            border-radius: 6px;
            font-size: 12px;
        }

        .fc-button-primary:hover
        {
            color: #4085c6;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            border: none;
        }

        .fc-button-primary:not(:disabled):active,
        .fc-button-primary:not(:disabled).fc-button-active
        {
            color: #4085c6;
            background: #fff;
            border: none;
        }

        .fc-button-group > {
            .fc-button:not(:last-child),
            .fc-button:not(:first-child)
            {
                border-radius: 6px;
            }

            .fc-button:not(:first-child) {
                margin: 2px;
            }
        }

        .fc-h-event{
            border-color: #fff;
        }
        .fc-open{
            color: #fff;
            background-color: #4085c6;
            border-radius: 6px;
            border-color: #4085c6;
        }  
        .fc-approval{
            color: #fff;
            background-color: rgb(199, 200, 202);
            border-radius: 6px;
            border-color: rgb(199, 200, 202);
        }  
        .fc-late{
            color: #fff;
            background-color: #ff4d4f;
            border-radius: 6px;
            border-color: #ff4d4f;
        }   
        .fc-pending{
            color: #fff;
            background-color: #2b579a;
            border-radius: 6px;
            border-color: #2b579a;
        }    
        .fc-done{
            color: #fff;
            background-color: rgb(103, 209, 129);
            border-radius: 6px;
            border-color: rgb(103, 209, 129);
        }  
        .fc-recurrency{
            color: #fff;
            background-color: #2c84c0;
            border-radius: 6px;
            border-color: #2c84c0;
        }      
        .fc-daygrid-event {
            box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
        }  
        .fc-col-header-cell-cushion, .fc-daygrid-day-number{
            color: #000;
        }
        .fc-daygrid-day.fc-day-today{
            background-color: #fff;
        }
        .fc-event-title{
            font-weight: 700;
            padding: 2px;
        }
        .fc-scrollgrid {
            background-color: #fff;
        }
        
        .fc-daygrid-event {
            border-radius: 6px;
        }

        /* .fc-scrollgrid-section table {
            margin-top: 0px;
            padding-top: 0px;
        } */

        /*
        .fc-scrollgrid-section-body table,
        .fc-scrollgrid-section-footer table
        {
            margin-top: 0px;
            padding-top: 0px;
        } */

        /*
        &.fc-theme-standard td,
        &.fc-theme-standard th
        {
            min-width: 110px;
            max-width: 110px;
        } */
    }
`
