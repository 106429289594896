const theme = {
    colors: {
        primary: "#4085c6",
        black: "#000000",
        lightText: "#929292",
    },
    text: {
        fontFamily: ""
    },
    workflow: {
        blocking: "#EBB41D",
        executed: "#237829",
        faulted: "#C52B2B",
        outcomes: {
            default: "#000000",
            done: "#1CAA12",
            notify: "#C52B2B",
            remind: "#EBB41D",
            late: "#EBB41D",
        },
        nodeBorder: {
            blocking: "#EBB41D",
            executed: "#29C61E",
            faulted: "#C52B2B",
        }
    }
}

export default theme;
