import { TreeSelect } from "antd";
import React, { useEffect, useState } from "react";
import { getTopicAsAntDTree, getUserTopicAsAntDTree } from "../../utils";

export function TreeSelectTopicComponent(props) {
  const [tree, setTree] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function load() {
      if (!props.topics || props.topics.length == 0) {
        setLoading(true);
        if(props.loadAllTopics){
          setTree(await getUserTopicAsAntDTree(props.disabledNodes ?? [], props.disableWholeTree ?? true));
        }else{
          setTree(await getTopicAsAntDTree(props.disabledNodes ?? [], props.disableWholeTree ?? true));
        }
      } else {
        setTree(await props.topics);
      }
      setLoading(false);
    }
    load();
  }, []);

  return (
    <TreeSelect
      allowClear
      defaultValue={props.defaultValue ?? undefined}
      multiple={props.multiple}
      showSearch
      treeNodeFilterProp={"title"}
      treeData={tree}
      loading={loading}
      {...props /* Unpack props like this to enable Antd 'Form.Item' propagate props to TreeSelect Component */}
    >
    </TreeSelect>
  );
}

export const TreeSelectTopic = React.memo(TreeSelectTopicComponent);
