import i18n from 'i18next';
import moment from 'moment-timezone';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { info_message, success_message } from '../components/Toastr';
import { ACTION, FEATURE, extractPermissions, permissionByRoute } from '../config/roles';
import api, { GetUserRolesById, GetUserTasksPending, SetApiBaseUrl } from '../services/api';
import { getTimestampDate, setExpirationUserData } from '../utils/helper';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [roles, setRoles] = useState();
    const [loadRoles, setLoadRoles] = useState(false);
    const [checkDate, setLastCheck] = useState(() => {
        const timeStampMilisecondsStr = localStorage.getItem('@Auth:LastCheckedTasks');
        if (timeStampMilisecondsStr) {
            const timeStampMiliseconds = Number(timeStampMilisecondsStr);
            if (!isNaN(timeStampMiliseconds)) {
                return moment(timeStampMiliseconds);
            }
        }
        return null;
    });

    useEffect(() => {
        async function l() {
            await loadUserPermissions();
            setLoadRoles(false);
        }
        if (loadRoles) {
            l();
        }
    }, [loadRoles]);

    const updateUserPermission = useCallback(() => {
        loadUserPermissions();
    }, []);

    const checkUpdateUserRoles = async (user) => {
        const now = moment();

        if (!checkDate || moment(checkDate).add(5, 'minutes').isBefore(now)) {
            const data = moment();
            setLastCheck(data);
            localStorage.setItem('@Auth:LastCheckedTasks', data.valueOf());
            await loadUserTasks();
        }

        if (!user?.profiles) {
            setExpirationUserData();
            setLoadRoles(true);
        } else {
            setLoadRoles(true);
        }
    };

    const loadUserTasks = async () => {
        try {
            let response = await GetUserTasksPending(can(FEATURE.FORMTASK, [ACTION.PENDINGTASK]));
            localStorage.setItem('@Auth:CheckedTasksCount', JSON.stringify(response.data.response));
        } catch (error) {
            console.error('Erro ao carregar tarefas do usuário:', error);
        }
    };

    const loadUserPermissions = async () => {
        try {
            const userLogged = JSON.parse(localStorage.getItem('@Auth:user'));
            if (userLogged && userLogged.userName === "anonymous") {
                return;
            }

            const token = GetUserToken();

            let dateTimeNow = userLogged?.expirationData
                ? moment(userLogged.expirationData * 1000).utc().format()
                : moment(1262347200 * 1000).utc().format(); // Data antiga: 01/01/2010 força o download dos roles

            let response = await GetUserRolesById(dateTimeNow);
            if (response.data.response == null) {
                let permissions = await parsePermissionToWeb(userLogged.profiles);
                setRoles(permissions);
                return;
            }

            if (!response.data.response.isActive) {
                info_message('Seu usuário não está ativo!');
                signOut();
                return;
            }

            if (moment(response.data.response.lastLogin).startOf('day').isBefore(moment().utc().startOf('day'))) {
                await api.post(`/User/lastLogin?userId=${userLogged.id}`);
            }

            let languageId = defaultOrAllowedLanguage(response?.data?.response?.languageId);
            if (languageId) {
                i18n.changeLanguage(languageId);
            }

            const { profiles, location, companyLocation, isFirstLogin, expiredPassword } = response.data.response;
            let permissions = await parsePermissionToWeb(profiles);
            setRoles(permissions);

            userLogged.location = location;
            userLogged.companyLocation = companyLocation;
            userLogged.profiles = profiles;
            userLogged.expirationData = getTimestampDate(1500); //25 min
            userLogged.isFirstLogin = isFirstLogin;
            userLogged.expiredPassword = expiredPassword;

            localStorage.setItem('@Auth:user', JSON.stringify(userLogged));
            setData({ token, user: userLogged });
        } catch (error) {
            console.log(error);
            info_message('Sessão expirada!');
            signOut();
        }
    };

    const parsePermissionToWeb = async (profiles) => {
        Array.prototype.groupBy = function (funcProp) {
            return this.reduce(function (acc, val) {
                (acc[funcProp(val)] = acc[funcProp(val)] || []).push(val);
                return acc;
            }, {});
        };

        let permissions = [];
        let listActions = [];
        profiles?.forEach((profile) => {
            profile.role?.featureActions?.forEach((feature) => {
                listActions.push({
                    role: profile.role.name,
                    feature: feature.action.feature.code,
                    actions: feature.action.code,
                });
            });
        });

        if (listActions.length > 0) {
            let groupbyFeatures = listActions.groupBy((c) => c.feature);
            for (const featureName in groupbyFeatures) {
                let newListActions = [];
                for (let i = 0; i < groupbyFeatures[featureName].length; i++) {
                    newListActions.push(groupbyFeatures[featureName][i].actions);
                }
                let actions = [...new Set(newListActions)];
                permissions.push({ feature: featureName, actions: actions });
            }
        }

        return permissions;
    };

    function UserPermissionAccess() {
        if (!roles || permissionByRoute.length === 0) {
            return {};
        }

        let featureValues = [];

        permissionByRoute.forEach((permissionParam) => {
            roles.forEach((role) => {
                if (role.feature === permissionParam.feature.toUpperCase()) {
                    const hasPermission = userHasPermissionActions(role.actions, permissionParam.actions);
                    featureValues.push({ feature: role.feature, value: hasPermission });
                }
            });
        });

        if (featureValues.length > 0) {
            return extractPermissions(featureValues);
        }

        return {};
    }

    const can = (permission, listActions) => {
        let hasPermission = false;
        if (roles && Array.isArray(listActions)) {
            let permissionRules = Object.entries(roles).filter(([key, role]) => {
                return role.feature === permission.toUpperCase();
            });

            if (permissionRules.length > 0) {
                let permissions = permissionRules[0][1];
                listActions.forEach((action) => {
                    if (permissions.actions.includes(action.toUpperCase())) {
                        hasPermission = true;
                    }
                });
            }
        }
        return hasPermission;
    };

    const canAccessPage = (permissionParams) => {
        let hasPermission = false;
        if (roles && permissionParams.length > 0) {
            permissionParams.forEach((permissionParam) => {
                Object.entries(roles).forEach(([key, role]) => {
                    let feature = permissionParam.feature.toUpperCase();
                    if (role.feature === feature) {
                        hasPermission = userHasPermissionActions(role.actions, permissionParam.actions);
                    }
                });
            });
        }
        return hasPermission;
    };

    const userHasPermissionActions = (userActions, paramActions) => {
        let hasAccess = false;
        userActions.forEach((actionUser) => {
            let actionUserValue = actionUser.toUpperCase();
            if (paramActions.includes(actionUserValue)) {
                hasAccess = true;
                return false;
            }
        });
        return hasAccess;
    };

    function defaultOrAllowedLanguage(languageId) {
        if (languageId !== "en-US" && languageId !== "pt-BR") {
            languageId = "en-US";
        }
        return languageId;
    }

    function getTranslationSystem(languageId) {
        if (languageId) {
            languageId = defaultOrAllowedLanguage(languageId);
            i18n.changeLanguage(languageId);
        }
    }

    const [data, setData] = useState(() => {
        const token = GetUserToken();
        const user = JSON.parse(localStorage.getItem('@Auth:user'));

        if (token && user) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
            if (user && user.userName !== "anonymous") {
                checkUpdateUserRoles(user);
                getTranslationSystem(user?.languageId);
            }
            AuthTokenExpirationMonitor(token);
            return { token, user };
        }
        return {};
    });

    const anonymous = async (result) => {
        const alreadyLoggedIn = localStorage.getItem('@Auth:AnonymousLoggedIn');

        // Verifica se o login anônimo já foi feito para evitar repetição
        if (alreadyLoggedIn) {
            return true; // Evita reiniciar o processo de login
        }

        SetApiBaseUrl(result.tenantName);

        api.defaults.headers.Authorization = `Bearer ${result.accessToken}`;

        let user = { ...result.user, locationId: result.user.locationId, isFirstLogin: false, expiredPassword: false };

        localStorage.setItem('@Auth:token', result.accessToken);
        localStorage.setItem('@Auth:user', JSON.stringify(user));
        localStorage.setItem('@Auth:tenant', result.tenantName);

        // Marca como logado anônimo no localStorage
        localStorage.setItem('@Auth:AnonymousLoggedIn', 'true');

        setData({ token: result.accessToken, user });

        return true;
    };

    const signIn = useCallback(
        async ({ login, password, tenant, recaptcha, isSSO = false, ssoToken = null }) => {
            try {
                SetApiBaseUrl(tenant);
                let response = null;

                if (!isSSO) {
                    response = await api.post(
                        'signin',
                        {
                            login,
                            password,
                            tenant,
                            recaptcha,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'tenant-id': tenant,
                            },
                        }
                    );
                } else {
                    response = await api.post(
                        'sso',
                        {
                            tenant: tenant,
                            token: ssoToken,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'tenant-id': tenant,
                            },
                        }
                    );
                }

                let token = response.data.response.accessToken;
                let profile = response.data.response.profiles[0];
                response.data.response.user.expirationData = getTimestampDate(1500);

                let user = {
                    ...response.data.response.user,
                    locationId: profile.locationId,
                    locationName: profile.locationName,
                    topics: profile.topics,
                    profiles: response.data.response.profiles,
                    isFirstLogin: response.data.response.isFirstLogin,
                    expiredPassword: response.data.response.expiredPassword,
                    customHomePage: response.data.response.customHomePage,
                };

                let permissions = await parsePermissionToWeb(response.data.response.profiles);
                setRoles(permissions);
                setLoadRoles(false);

                api.defaults.headers.Authorization = `Bearer ${token}`;

                localStorage.setItem('@Auth:token', token);
                localStorage.setItem('@Auth:user', JSON.stringify(user));
                localStorage.setItem('@Auth:tenant', tenant);
                localStorage.removeItem('@Auth:CheckedTasksCount');
                localStorage.removeItem('@Auth:LastCheckedTasks');
                getTranslationSystem(user?.languageId);
                await loadUserTasks();
                await api.post(`/User/lastLogin?userId=${user.id}`);

                success_message('Bem-vindo ao Flowy!');
                setData({ token, user });
            } catch (error) {
                throw error;
            }
        },
        []
    );

    const signOut = useCallback(() => {
        sessionStorage.clear();
        localStorage.clear();
        info_message('Logout realizado com sucesso!');
        setData({});
        setRoles({});
        window.location.href = "/";
    }, []);

    const updateUser = useCallback(
        (user) => {
            localStorage.setItem('@Auth:user', JSON.stringify(user));
            setData({
                token: data.token,
                user,
            });
        },
        [data.token]
    );

    return (
        <AuthContext.Provider
            value={{
                user: data.user,
                signIn,
                signOut,
                updateUser,
                can,
                canAccessPage,
                UserPermissionAccess,
                getTranslationSystem,
                updateUserPermission,
                anonymous,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('O método useAuth deve ser usado dentro de um AuthProvider');
    }
    return context;
}

function AuthTokenExpirationMonitor(token) {
    // 1. Verificação inicial se o token existe
    if (!token) {
        console.error('Nenhum token fornecido para monitoramento.');
        return;
    }

    try {
        // 2. Dividir o token para extrair o payload e obter as informações de expiração
        const [header, payload, signature] = ParseTokenJWT(token);

        // 3. Verificar se a extração do payload ocorreu corretamente
        if (!payload || !payload.exp) {
            console.error('Falha ao extrair informações do token ou falta de campo exp no payload.');
            return;
        }

        // 4. Conversão de `exp` do token para data em UTC (em milissegundos)
        const expToken = moment(payload.exp * 1000).utc();
        const currentDate = moment().utc();

        // 5. Log das informações de data para verificação
        console.log(`Data de Expiração do Token (UTC): ${expToken.format('YYYY-MM-DD HH:mm:ss')}`);
        console.log(`Data Atual (UTC): ${currentDate.format('YYYY-MM-DD HH:mm:ss')}`);
        console.log(`Diferença em minutos: ${expToken.diff(currentDate, 'minutes')} min`);

        // 6. Validação e comparação de datas
        if (expToken.isBefore(currentDate)) {
            console.warn('Sessão expirada! O token está vencido.');
            console.warn(`Token expirou em: ${expToken.format('YYYY-MM-DD HH:mm:ss')}`);
            sessionStorage.clear();
            localStorage.clear();
            info_message('Sessão expirada!');
            window.location.href = "/";
        } else {
            console.log(`Token válido. Expira em ${expToken.diff(currentDate, 'minutes')} minutos.`);
        }
    } catch (error) {
        // 7. Tratamento de erro com informações de falha
        sessionStorage.clear();
        localStorage.clear();
        info_message('Sessão expirada!');
        window.location.href = "/";
    }
}

function ParseTokenJWT(token) {
    try {
        const [header, payload, signature] = token.split('.');

        // 1. Parse dos segmentos do JWT para objetos JSON
        const headerParsed = JSON.parse(window.atob(header));
        const payloadParsed = JSON.parse(window.atob(payload));

        return [headerParsed, payloadParsed, signature];
    } catch (error) {
        sessionStorage.clear();
        localStorage.clear();
        info_message('Sessão expirada!');
        window.location.href = "/";
    }
}

function GetUserToken() {
    const token = localStorage.getItem('@Auth:token');
    return token;
}

export function GetUserTennantCanonicalName() {
    const token = GetUserToken();
    if (token) {
        const [header, payload] = ParseTokenJWT(token);
        return payload.tenantId;
    }
}

export { AuthProvider, AuthTokenExpirationMonitor, useAuth };
