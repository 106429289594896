import styled from 'styled-components';

export const Container = styled.div`
    margin: auto;
    width: 100%;
`;

export const ContainerBackground = styled.div`
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    overflow-x: hidden;

    .ant-card-body {
        padding: 0px !important;
        border-radius: 12px;
    }

    form {
        display: flex;
        z-index: 9999;
        width: 100%;
        height: 100%;
    }

    .card-transition {
        transition: max-height 0.5s ease-in-out;
        overflow: hidden;
    }

    .card-expanded {
        max-height: 1000px; /* Ajuste isso para uma altura máxima suficiente para o conteúdo expandido */
    }
`;

export const ImageFlowyFooter = styled.div`
    margin: auto;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
`;
