export const FEATURE = {
  FORMBUILDER: 'FORMBUILDER',
  LOCATION: 'LOCATION',
  TREELIST: 'TREELIST',
  LIST: 'LIST',
  TENANT: 'TENANT',
  FORMTASK: 'FORMTASK',
  SCHEDULE: 'SCHEDULE',
  DATA: 'DATA',
  ROLE: 'ROLE',
  USER: 'USER',
  TOPIC: 'TOPIC',
  ACCESS: 'ACCESS',
  DOCUMENTCONTROL: 'DOCUMENTCONTROL',
  POWERBI: 'POWERBI',
  ORIGININFORMATION: 'ORIGININFORMATION',
  DOCTYPE: "DOCTYPE",
  REPORTS:"REPORTS"
};

export const ACTION = {
  VIEW: 'VIEW',
  DELEGATE: 'DELEGATE',
  MANAGE: 'MANAGE',
  EXPORT: 'EXPORT',
  IMPORT: 'IMPORT',
  DELETE: 'DELETE',
  REOPEN: 'REOPEN',
  CLOSETASK: 'CLOSETASK',
  PENDINGTASK: 'PENDINGTASK',
  CREATE: 'CREATE',
  APPLYFORM: 'APPLYFORM',
  EDIT: 'EDIT',
  APPROVER: 'APPROVER',
  TRANSLATE: 'TRANSLATE',
  WEBACCESS: 'WEBACCESS',
  DATAVIEW: 'DATAVIEW',
  DATAIMPORT: 'DATAIMPORT',
  DATAEXPORT: 'DATAEXPORT',
  DATADELETE: 'DATADELETE',
  DCEXPORT: 'DCEXPORT',
  DCCOMMON: 'DCCOMMON',
  DCCONFIDENTIAL: 'DCCONFIDENTIAL',
  DCEXCLUDE: 'DCEXCLUDE',
  DCEDIT: 'DCEDIT',
  DCSHARE: 'DCSHARE',
  DCREVISION: 'DCREVISION',
  DCPRINT: 'DCPRINT',
  DCREDELEGATE: 'DCREDELEGATE',
  DCAPPROVER: 'DCAPPROVER',
  FAVORITEGROUP: 'FAVORITEGROUP',
  RESETPASSWORD: 'RESETPASSWORD',
  VIEWALL:"VIEWALL",
  DCPASSWORD: 'DCPASSWORD',
  DCMASTER: 'DCMASTER',
};

/**
 * Full permissions with feacture
 * use in react route 
 */

export const permissionByRoute = [
  { feature: FEATURE.FORMBUILDER, actions: [ACTION.EXPORT, ACTION.DELETE, ACTION.TRANSLATE, ACTION.VIEW, ACTION.EDIT, ACTION.CREATE, ACTION.MANAGE, ACTION.FAVORITEGROUP] },
  { feature: FEATURE.SCHEDULE, actions: [ACTION.VIEW, ACTION.DELETE, ACTION.CREATE, ACTION.EDIT, ACTION.DELEGATE] },
  { feature: FEATURE.FORMTASK, actions: [ACTION.VIEW, ACTION.APPLYFORM, ACTION.DELETE, ACTION.REOPEN, ACTION.CLOSETASK, ACTION.PENDINGTASK] },
  { feature: FEATURE.TENANT, actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE] },
  { feature: FEATURE.TOPIC, actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE] },
  { feature: FEATURE.USER, actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE, ACTION.RESETPASSWORD] },
  { feature: FEATURE.LOCATION, actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE] },
  { feature: FEATURE.LIST, actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE] },
  { feature: FEATURE.ROLE, actions: [ACTION.VIEW, ACTION.MANAGE] },
  { feature: FEATURE.DATA, actions: [ACTION.DATAVIEW, ACTION.DATAEXPORT, ACTION.DATADELETE, ACTION.DATAIMPORT] },
  { feature: FEATURE.ACCESS, actions: [ACTION.WEBACCESS] },
  { feature: FEATURE.TREELIST, actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE] },
  { feature: FEATURE.DOCUMENTCONTROL, actions: [ACTION.DCCOMMON, ACTION.DCCONFIDENTIAL, ACTION.DCEDIT, ACTION.DCEXCLUDE, ACTION.DCEXPORT, ACTION.DCPRINT, ACTION.DCREDELEGATE, ACTION.DCREVISION, ACTION.DCAPPROVER, ACTION.DCMASTER] },
  { feature: FEATURE.POWERBI, actions: [ACTION.VIEW] },
  { feature: FEATURE.ORIGININFORMATION, actions: [ACTION.VIEW, ACTION.MANAGE] },
  { feature: FEATURE.DOCTYPE, actions: [ACTION.VIEW, ACTION.MANAGE] }
];

/**
 * 
 * @param {Object[]} userPermissions 
 * @returns user can access route
 */
export const extractPermissions = (userPermissions) => {

  function getItemValue(item) {
    return item[1].value;
  };

  // // console.log("featureValues:" + JSON.stringify(userPermisssions));
  let formbuilder = false;
  let location = false;
  let treelist = false;
  let list = false;
  let tenant = false;
  let formtask = false;
  let role = false;
  let data = false;
  let user = false;
  let topic = false;
  let access = false;
  let schedule = false;
  let documentcontrol = false;
  let powerbi = false;
  let origininformation = false;
  let doctype = false;

  if (userPermissions != null) {
    if (userPermissions.length > 0) {
      Object.entries(userPermissions).forEach(item => {
        switch (item[1].feature) {
          case "FORMBUILDER":
            formbuilder = getItemValue(item);
            break;
          case "LOCATION":
            location = getItemValue(item);
            break;
          case "TREELIST":
            treelist = getItemValue(item);
            break;
          case "LIST":
            list = getItemValue(item);
            break;
          case "TENANT":
            tenant = getItemValue(item);
            break;
          case "FORMTASK":
            formtask = getItemValue(item);
            break;
          case "ROLE":
            role = getItemValue(item);
            break;
          case "SCHEDULE":
            schedule = getItemValue(item);
            break;
          case "USER":
            user = getItemValue(item);
            break;
          case "DATA":
            data = getItemValue(item);
            break;
          case "TOPIC":
            topic = getItemValue(item);
            break;
          case "ACCESS":
            access = getItemValue(item);
            break;
          case "DOCUMENTCONTROL":
            documentcontrol = getItemValue(item);
            break;
          case "POWERBI":
            powerbi = getItemValue(item);
            break;
          case "ORIGININFORMATION":
            origininformation = getItemValue(item);
            break;
          case "DOCTYPE":
            doctype = getItemValue(item);
            break;
          default:
            break;
        }
      });
    }
  }
  return { formbuilder, location, treelist, list, tenant, formtask, role, schedule, user, topic, data, access, documentcontrol, powerbi, origininformation, doctype };
}