import Api from "../api";
var basePath = "/ListTree";
export function get(id) {
    var languageId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var includeLocation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var includeTopic = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var includeItems = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    var includeTranslations = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    return Api.get("".concat(basePath, "/get?id=").concat(id, "&languageId=").concat(languageId, "&includeLocation=").concat(includeLocation, "&includeTopic=").concat(includeTopic, "&includeItems=").concat(includeItems, "&includeTranslations=").concat(includeTranslations));
}
export function getAll(request) {
    return Api.post("".concat(basePath, "/getAll"), request);
}
export function getAllTreelist() {
    return Api.post("".concat(basePath, "/getAllTreelist"), null);
}
