import styled from "styled-components";

export const Container = styled.div`

    /* enable to new menu */
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: var(--sidebar-option-selected);
    }

    .ant-divider-horizontal{
      background-color: #ffffff3b; 
      margin: 0px 0px 14px 0px !important;
    }
    .ant-menu-dark .ant-menu-item-selected {
        border-right: 0;
    }

    //enable in new menu
    /* & .ant-menu-item.menu-item a::before,
      .ant-menu-submenu.ant-menu-submenu-inline {
      border-bottom: 0.2px solid var(--sidebar-subMenuItems-border-color);
    } */

    .ant-menu.ant-menu-dark, 
    .ant-menu-dark .ant-menu-sub, 
    .ant-menu.ant-menu-dark .ant-menu-sub {
        background: var(--sidebar-background);
    }

    /* Ajusts sidebar icons and menus */
    // inside submenu items 
    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: var(--sidebar-subMenuItems-background) !important;
    }

    //icons menu alignment (the simple menu item)
    /* & .ant-menu-item .ant-menu-item-icon {
        margin-right: 18px;
    } */

    //ajusts alignment icons Menu.SubMenu 
    /* & .ant-menu-submenu-title, svg{
      min-width: 14px; */
      /* margin-right: 18px; */ //enable in new menu
      /* font-size: 14px;
      transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
    } */

    //ajusts alignment icons internal items Menu.SubMenu 
    /* & li.ant-menu-item.submenu-item svg{
      margin-right: 10px;
    } */

    //intenal submenu items
    /* & li.ant-menu-item.submenu-item {
      padding-left: 30px !important;
    } */
    
`;