import styled from "styled-components";
import { Image } from "antd";
import Icon from '../../assets/branding/Flowy_logo_principal_blue.png';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 50px;
    max-height: 50px;
`;

export default function IconAppLogo() {
    return (
        <Container>
            <Image preview={false} src={Icon} style={{ maxHeight: 50, margin: 'auto' }} />
        </Container>
    )
}
