import { ArrowLeftOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Button, Card, Col, Divider, Form, Image, Input, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon2 from '../../assets/branding/Flowy_logo_principal_white.png';
import { useAuth } from '../../hooks/auth';
import api, { SetApiBaseUrl, exceptionNotificationAPI } from '../../services/api';
import { msalConfig } from '../../utils/ssoConfig';
import { Container, ContainerBackground } from '../SignIn/styles';

const SignIn = () => {
    const [loadingSendTo, setLoadingSendTo] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [verified, setVerified] = useState(false);
    const [rec, setRec] = useState('');
    const { t } = useTranslation();
    const { signIn, user } = useAuth();
    const [form] = Form.useForm();
    let navigate = useNavigate();
    const [selectedTenant, setSelectedTenant] = useState('');
    const [inputTenant, setInputTenant] = useState('');
    const [isFlowyAccount, setIsFlowyAccount] = useState(false);
    const [ssoConfigurations, setSSOConfigurations] = useState(null);
    const [hasTenantOnParameter, setHasTenantOnParameter] = useState(false);
    const { tenant } = useParams();
    let { instance } = useMsal();

    useEffect(() => {
        if (user)
            navigate("/home");
    }, [user])

    useEffect(() => {
        async function load() {
            setLoadingPage(true);
            if (tenant) {
                setHasTenantOnParameter(true);
                await selectTenant(tenant).then(async (ret) => {
                    if (ret.isSSOEnabled) {
                        await handleSSO({ 'ssoConfiguration': ret });
                    }
                });
            }
            setLoadingPage(false);
        }
        load();
    }, []);

    const handleSubmit = async (data) => {
        try {
            setLoadingSendTo(true);

            await signInAndRedirect(data).catch((error) => { throw error; });;
        } catch (error) {
            if (window.grecaptcha) grecaptcha.reset();
            setLoadingSendTo(false);
            exceptionNotificationAPI(error);
            setVerified(false);
        }
    };

    const handleSSO = async ({ ssoConfiguration = null }) => {
        setLoadingSendTo(true);
        ssoConfiguration = ssoConfiguration || ssoConfigurations;
        const url = document.location.href.split('/')[0] + '//' + document.location.href.split('/')[2];
        try {
            const configuration = {
                ...msalConfig,
                auth: {
                    clientId: ssoConfiguration.appClientId,
                    authority: "https://login.microsoftonline.com/" + ssoConfiguration.appTenantId,
                    redirectUri: url
                }
            }

            instance = new PublicClientApplication(configuration);
            const loginRequest = { "scopes": [ssoConfiguration.scope] }
            const token = await ssoRequestToken(loginRequest);
            if (token) {
                const data = { 'isSSO': true, 'ssoToken': token }
                await signInAndRedirect(data).catch((error) => { throw error; });
            }
            else {
                setLoadingSendTo(false);
            }
        }
        catch (error) {
            if (window.grecaptcha) grecaptcha.reset();
            setLoadingSendTo(false);
            exceptionNotificationAPI(error);
            setVerified(false);
        }
    };

    const getTenantSSOInformation = async ({ tenant = null }) => {
        tenant = tenant || selectedTenant;
        const response = await api.post('getTenantSSOInformation', { 'Tenant': tenant }, { headers: { 'Content-Type': 'application/json', 'tenant-id': tenant, } });
        setSSOConfigurations(response.data.response);
        return response.data.response;
    }

    const ssoRequestToken = async (loginRequest) => {
        var token = '';

        await instance.loginPopup(loginRequest)
            .then((response) => {
                token = response.accessToken;
            })
            .catch(error => {
                console.log(error);
                if (window.grecaptcha) grecaptcha.reset();
                setLoadingSendTo(false);
                exceptionNotificationAPI(error);
                setVerified(false);
            });

        return token;
    };

    const signInAndRedirect = async (data) => {
        try {
            await signIn({
                login: data?.login,
                password: data?.password,
                tenant: selectedTenant ?? tenant,
                recaptcha: rec,
                isSSO: data?.isSSO,
                ssoToken: data?.ssoToken,
            });

            const user = JSON.parse(localStorage.getItem('@Auth:user'));
            const tenant = localStorage.getItem('@Auth:tenant');

            if (user.isFirstLogin == true || user.expiredPassword == true) {
                navigate('/changePassword');
            } else {
                if (user.customHomePage) {
                    navigate('/home/custom');
                } else {
                    navigate('/home');
                }
            }
        } catch (error) {
            throw error;
        }
    };

    const handleReturn = () => {
        if (isFlowyAccount) {
            setIsFlowyAccount(false);
            if (!ssoConfigurations.isSSOEnabled && !tenant) {
                setSelectedTenant('');
            }
        }
        else if (selectedTenant && !tenant) {
            setSelectedTenant('');
        }
    }

    const selectTenant = async (tenant = null) => {
        setLoadingSendTo(true);
        tenant = tenant || inputTenant;

        if (tenant == "") {
            setLoadingSendTo(false);
            return;
        }

        SetApiBaseUrl(tenant);
        const ssoConfiguration = await getTenantSSOInformation({ 'tenant': tenant });

        if (ssoConfiguration && !ssoConfiguration.isSSOEnabled) {
            setIsFlowyAccount(true);
        }

        setSelectedTenant(tenant);

        setLoadingSendTo(false);
        return ssoConfiguration;
    }

    const handleTenantInputKeyPress = async (e) => {
        if (e.keyCode === 13) {
            selectTenant();
        }
    }

    return (
        <ContainerBackground style={{ backgroundColor: "#f8f9fd" }}>
            <Row gutter={[16, 16]} justify="center" align="middle" style={{ height: "100%" }}>
                <Col span={24}>
                    <Card className={`card-transition ${selectedTenant || isFlowyAccount ? 'card-expanded' : ''}`} style={{ padding: 0, margin: "auto", width: 1000, borderRadius: 12, boxShadow: "0px 10px 34px -15px rgba(0, 0, 0, 0.24)" }}>
                        <Row>
                            <Col span={11} style={{ display: 'flex', flexDirection: "column", background: '#4085c6', padding: 24, borderTopLeftRadius: 12, borderBottomLeftRadius: 12, justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                                <Image preview={false} src={Icon2} style={{ margin: 'auto', width: '100%' }} />
                            </Col>
                            <Col span={13} style={{ padding: 48, backgroundColor: '#fff', borderRadius: 12, height: "100%", margin: "auto" }}>
                                <h1 className="font-face-gbold" style={{ color: "#00000080" }}>Login</h1>
                                <Divider />
                                {!loadingPage && !selectedTenant &&
                                    <Container className="font-face-gbook">
                                        <span className="font-face-gbold" style={{ color: "#00000080" }}>{t('Login.Client')}</span>
                                        <Input
                                            style={{ marginTop: 10 }}
                                            disabled={loadingSendTo}
                                            placeholder={t('Login.Tenant')}
                                            onChange={(e) => { setInputTenant(e.target.value) }}
                                            value={inputTenant}
                                            onKeyUp={handleTenantInputKeyPress}
                                        />

                                        <Divider />

                                        {!loadingSendTo ?
                                            <Button
                                                type="primary"
                                                block
                                                loading={loadingSendTo}
                                                disabled={loadingSendTo}
                                                className="font-face-gbold"
                                                onClick={async () => { await selectTenant() }}
                                            >
                                                {t('Login.Continue')}
                                            </Button>
                                            :
                                            <Spin style={{ display: 'flex', margin: 'auto', justifyContent: 'center', alignItems: 'center' }} indicator={<LoadingOutlined spin />} size="large" />
                                        }
                                    </Container>
                                }

                                {selectedTenant && !isFlowyAccount && !loadingPage &&
                                    <Container>
                                        {!hasTenantOnParameter && <span className="font-face-gbold" style={{ color: "#00000080" }}><ArrowLeftOutlined style={{ marginRight: 10 }} onClick={handleReturn} /> {t('Login.Tenant')}: <strong>{inputTenant}</strong></span>}

                                        <Divider />

                                        <Row gutter={[16, 16]}>
                                            <Col span={12}>
                                                <Button
                                                    type="primary"
                                                    block
                                                    loading={loadingSendTo}
                                                    disabled={loadingSendTo}
                                                    display={loadingSendTo ? "none" : "flex"}
                                                    className="ant-btn sign-in-option-btn"
                                                    onClick={() => { setIsFlowyAccount(true) }}
                                                >
                                                    {t('Login.FlowyAccount')}
                                                </Button>
                                            </Col>
                                            <Col span={12}>
                                                {!loadingSendTo ?
                                                    <Button
                                                        type="primary"
                                                        block
                                                        loading={loadingSendTo}
                                                        disabled={loadingSendTo}
                                                        className="ant-btn sign-in-option-btn"
                                                        onClick={handleSSO}
                                                    >
                                                        {t('Login.MicrosoftAuthentication')}
                                                    </Button>
                                                    :
                                                    <Spin style={{ display: 'flex', margin: 'auto', justifyContent: 'center', alignItems: 'center' }} indicator={<LoadingOutlined spin />} size="large" />
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                }

                                {isFlowyAccount && !loadingPage &&
                                    <Form
                                        form={form}
                                        name="normal_login"
                                        layout="vertical"
                                        initialValues={{
                                            login: '',
                                            password: '',
                                        }}
                                        onFinish={handleSubmit}
                                        requiredMark={false}
                                    >
                                        <Container className="font-face-gbook">
                                            <Row>
                                                <Col span={24} style={{ marginBottom: 15 }}>
                                                    <span className="font-face-gbold" style={{ color: "#00000080" }}>
                                                        {
                                                            (!hasTenantOnParameter || ssoConfigurations.isSSOEnabled) &&
                                                            <ArrowLeftOutlined className='return-btn' style={{ marginRight: 10 }} onClick={handleReturn} />
                                                        }
                                                        {t('Login.UserPass')} <strong>{inputTenant}</strong>
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Form.Item
                                                name="login"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('Required.Custom.Message', { grammaticalPunct: '!', fieldName: `${t('Login.UserName')}`.toLowerCase(), }),
                                                    },
                                                ]}
                                            >
                                                <Input disabled={loadingSendTo} size="large" placeholder={t('Login.UserName')} />
                                            </Form.Item>

                                            <Form.Item
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('Required.Custom.Message4', { grammaticalPunct: '!', fieldName: `${t('Login.Passw')}`.toLowerCase(), }),
                                                    },
                                                ]}
                                            >
                                                <Input.Password disabled={loadingSendTo} size="large" placeholder={t('Login.Passw')} iconRender={(visible) => visible ? (<EyeTwoTone />) : (<EyeInvisibleOutlined />)} />
                                            </Form.Item>

                                            <ReCAPTCHA
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                                sitekey="6LdpBMAfAAAAADiHk_d3ezoT4sq0pMy-h0a2nMeC"
                                                onChange={(e) => {
                                                    setRec(e);
                                                    setVerified(true);
                                                }}
                                                onExpired={() => setVerified(false)}
                                            />

                                            <Divider />

                                            <Form.Item style={{ marginBottom: 0 }}>
                                                {!loadingSendTo ? (
                                                    <Button
                                                        type="primary"
                                                        block
                                                        htmlType="submit"
                                                        loading={loadingSendTo}
                                                        disabled={loadingSendTo}
                                                        className="font-face-gbold"
                                                    >
                                                        <strong>{t('Login.SignIn')}</strong>
                                                    </Button>
                                                ) : (
                                                    <Spin style={{ display: 'flex', margin: 'auto', justifyContent: 'center', alignItems: 'center', }} indicator={<LoadingOutlined spin />} size="large" />
                                                )}

                                                <Link style={{ marginLeft: 10, float: "right" }} name="forgotPassword" to="/user/send-reset-password">
                                                    {t('Login.ForgotPassw')}
                                                </Link>
                                            </Form.Item>
                                        </Container>
                                    </Form>}
                            </Col>
                        </Row>
                    </Card>

                    <Row style={{ padding: 24, margin: "auto", width: 1000 }}>
                        <Col span={12}>
                            <span className="font-face-gbold" style={{ color: "#3c3c3b", marginTop: 20, fontSize: 20, textTransform: "uppercase" }}>
                                {t('Login.FooterText1')}
                            </span>
                            <br />
                            <span className="font-face-gbold" style={{ color: "#3c3c3b", marginTop: 20, fontSize: 16 }}>
                                {t('Login.FooterText2')}
                            </span>
                            <span className="font-face-gbold" style={{ color: "#3c3c3b", marginTop: 20, fontSize: 28, fontWeight: "bold" }}>
                                {t('Login.FooterText3')}
                            </span>
                            <span className="font-face-gbold" style={{ color: "#4085c6", marginTop: 20, fontSize: 28 }}>.</span>
                        </Col>
                        <Col span={12}>
                            <Row style={{ width: '100%' }}>
                                <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <span className="font-face-gbold" style={{ color: "#3c3c3b", fontSize: 16, textTransform: "uppercase" }}>
                                        {t('Login.FooterText4')}
                                    </span>
                                </Col>
                                <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: 5 }}>
                                    <Link style={{ marginLeft: 10, float: "right", color: "#4085c6" }} target='_blank' to="https://instagram.com/flowy.app">
                                        <FaInstagram size={28} />
                                    </Link>
                                    <Link style={{ marginLeft: 10, float: "right", color: "#4085c6" }} target='_blank' to="https://www.linkedin.com/company/flowycc/">
                                        <FaLinkedin size={28} />
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row >
        </ContainerBackground >
    );
};

export default SignIn;
