import { Progress, Col, Row } from 'antd';
import Api, { exceptionNotificationAPI, Api_v2 } from '../../services/api';
import React, { useCallback, useEffect, useState } from 'react';

export const OriginInformationProgress = ({ formId, }) => {

    const [showProgress, setShowProgress] = useState(false);
    const [originProgressItems, setOriginProgressItems] = useState([]);

    const getOriginInformationProgress = useCallback(() => {
        Api.get(`/Form/getOriginInformationProgress?formId=${formId}`)
            .then(r => {
                if (r.data) {
                    if (r.data.response && r.data.response.length > 0) {
                        setOriginProgressItems(r.data.response);
                        setShowProgress(true);
                    }
                }
            })
            .catch(error => {
                exceptionNotificationAPI(error);
            });
    });

    useEffect(() => {
        setShowProgress(false);
        getOriginInformationProgress();
    }, [])


    return (showProgress &&
        <>
            {
                originProgressItems.map(item => {
                    return <Row>
                        <Col span={24} style={{ paddingBottom: 20 }}>
                            {/* <strong>{`(v${item.formVersion}) ${item.formName} - ${item.originInformationName}`}</strong> */}
                            <strong>{`${item.formName} - ${item.originInformationName}`}</strong>
                            <div style={{ paddingRight: 30 }}>
                                <Progress percent={item.answeredOptionsPercent} format={(percent) => `${item.answeredOptionsQty}/${item.totalOptionsQty}`} />
                            </div>
                        </Col>
                    </Row>
                })
            }
        </>
    );
}