import { Select } from 'antd';
import React, { useEffect, useState } from "react";

export function SelectSearchComponent(props) {
  const { placeholder = '', optionsData = null, functionRequestApi = null, onBlur = null, onFocus = null, style = '' } = props;

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const [value, setValue] = useState();
  let [tempValue, setTempValue] = useState(value);
  let [timer, setTimer] = useState();
  let [sendValue, setSendValue] = useState(false);

  useEffect(() => {
    setOptions(optionsData);
    setIsLoading(false);
  }, [optionsData]);

  useEffect(() => {
    async function load() {
      if (sendValue && tempValue.length > 2 && tempValue !== null) {
        setIsLoading(true);
        await functionRequestApi(tempValue, 10, 1);
        setIsLoading(false);
      }
    }
    load();
  }, [sendValue])

  useEffect(() => {
    if (value !== tempValue && value !== null) {
      setTempValue(value);
    }
  }, [value])

  let handleSearch = (value) => {
    if (typeof functionRequestApi === 'function') { // Ensure functionRequestApi is a function
      setSendValue(false);
      setIsLoading(true);
      setTempValue(value);

      if (timer) {
        clearTimeout(timer);
      }

      setTimer(setTimeout(() => {
        setSendValue(true)
      }, 300));
    } else {
      // Handle the case where functionRequestApi is not a function
      console.error("functionRequestApi is not a function");
    }
  }

  return (
    <Select
      allowClear
      showSearch
      placeholder={placeholder}
      options={options}
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      loading={isLoading}
      optionFilterProp="label"
      onSearch={typeof functionRequestApi === 'function' ? handleSearch : undefined}
      {...props}
    />
  )
}
export const SelectSearch = React.memo(SelectSearchComponent);