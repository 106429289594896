import axios from 'axios';
import { error_message } from '../components/Toastr';
import { GetUserTennantCanonicalName } from '../hooks/auth';
import * as Form from './api/form';
import * as Location from './api/location';
import * as Workflow from './api/workflow';
import * as FormTask from './api/formTask';
import * as User from './api/User';
import * as ListTree from './api/ListTree';
import * as ListTreeItem from './api/ListTreeItem';

const tennantCanonicalName = GetUserTennantCanonicalName();
const baseUrl = getTennantApiUrl(tennantCanonicalName);

const Api = axios.create({
    baseURL: baseUrl, //this is undefined if the user is not logged
});

export default Api;

// This export is deprecated, please use Api_v2
export { Form, Location };

export const Api_v2 = {
    Form,
    Location,
    Workflow,
    FormTask,
    User,
    ListTree,
    ListTreeItem,
};

export function SetApiBaseUrl(tennantCanonicalName) {
    const baseURL = getTennantApiUrl(tennantCanonicalName);
    Api.defaults.baseURL = baseURL;
}

function getTennantApiUrl(tennant) {
    //return process.env.REACT_APP_API_BASE_URL; //Return directly until we finish the Universal Migration. After that, remove this line;

    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_API_BASE_URL;
    }

    switch (tennant?.toLowerCase()) {
        case 'universal': {
            var url =
                document.location.href.split('/')[0] +
                '//' +
                document.location.href.split('/')[2] +
                '/';
            if (url != null && url.includes('https://app-dev.flowy.cc/')) {
                return process.env.REACT_APP_API_BASE_URL; // If you need to change this URL, check .env file in the root folder of the project
            } else if (
                url != null &&
                url.includes('https://app-qa.flowy.cc/')
            ) {
                return 'https://apiult-qa.flowy.cc/';
            } else {
                return 'https://apiult.flowy.cc/';
            }
        }

        case 'jti': {
            var url =
                document.location.href.split('/')[0] +
                '//' +
                document.location.href.split('/')[2] +
                '/';
            if (url != null && url.includes('https://app-dev.flowy.cc/')) {
                return process.env.REACT_APP_API_BASE_URL; // If you need to change this URL, check .env file in the root folder of the project
            } else if (
                url != null &&
                url.includes('https://app-qa.flowy.cc/')
            ) {
                return 'https://apijti-qa.flowy.cc/';
            } else {
                return 'https://apijti.flowy.cc/';
            }
        }
        case 'orca': {
            var url =
                document.location.href.split('/')[0] +
                '//' +
                document.location.href.split('/')[2] +
                '/';
            if (url != null && url.includes('https://app-dev.flowy.cc/')) {
                return 'https://apiorca-dev.flowy.cc/';
            } else if (
                url != null &&
                url.includes('https://app-qa.flowy.cc/')
            ) {
                return 'https://apiorca-qa.flowy.cc/';
            } else {
                return 'https://apiorca.flowy.cc/';
            }
        }
        case 'premium': {
            var url =
                document.location.href.split('/')[0] +
                '//' +
                document.location.href.split('/')[2] +
                '/';
            if (url != null && url.includes('https://app-dev.flowy.cc/')) {
                return 'https://apiptg-dev.flowy.cc/';
            } else if (
                url != null &&
                url.includes('https://app-qa.flowy.cc/')
            ) {
                return 'https://apiptg-qa.flowy.cc/';
            } else {
                return 'https://apiptg.flowy.cc/';
            }
        }
        default: {
            return process.env.REACT_APP_API_BASE_URL; // If you need to change this URL, check .env file in the root folder of the project
        }
    }
}

export const APIWithOutTenant = async (
    url,
    tenant,
    typeRequest,
    headers,
    data
) => {
    let linktenant = getTennantApiUrl(tenant);
    if (typeRequest.toUpperCase() == 'POST') {
        return axios.post(linktenant + url, headers, data);
    } else {
        return axios.get(linktenant + url, headers, data);
    }
};

/**
 * Pass object catch error
 * @param {Object} error
 */
export const exceptionNotificationAPI = (error) => {
    console.error(error);

    try {
        if (/500/.test(error.message)) {
            error_message('Failed to connect Web Service (500)');
        } else if (/403/.test(error.message)) {
            error_message('Unauthorized access (403)');
        } else if (/404/.test(error.message)) {
            error_message('Route Not Found (404)');
        } else if (/400/.test(error.message)) {
            let notifications = error.response.data.notifications;
            if (notifications && notifications.length > 0) {
                notifications.forEach((not) => {
                    error_message(not.message);
                });
            }
        } else {
            error_message(error.message);
        }
    } catch {
        error_message('Please inform catch object!');
    }
};

export const locationGetAll = Location.getAll;
export const locationGetAllSimple = Location.getAllSimple;
export const getLocationByCompanyId = Location.getLocationByCompanyId;

export const getUserCompanyByUserId = async (userId = '') => {
    const result = await Api.get(
        `/User/getUserCompanyIdByUser?userId=${userId}`
    );
    return result.data.response;
};

/**
 *
 * @param {string} ListTreeItems ListTreeId
 */
export const treeListItemsGetAll = async (ListTreeId = '') => {
    const result = await Api.post(
        `/ListTree/items/getAll?ListTreeId=${ListTreeId}`
    );
    return result;
};

export const originItemsGetAll = async (OriginInformationId = '') => {
    const result = await Api.post(`/OriginInformation/getItemsById`, { originInformationId: OriginInformationId });
    return result;
};

/**
 *
 * @param {string} ListTree
 */
export const treeListGetAll = async (searchLocation = '', searchTopic = '') => {
    let searchFilter = {
        locationId: searchLocation,
        topicId: searchTopic,
        searchText: '',
        status: '',
        orderField: 'name',
        ascendingOrder: false,
        pageNumber: 1,
        pageSize: 10,
    };
    const result = await Api.post(`/ListTree/getAll`, searchFilter);
    return result;
};

/**
 *
 * @param {string} id
 * @param {string} languageId
 */
export const treeListGetById = async (id, languageId = '') => {
    return Api.get(`ListTree/get?id=${id}&languageId=${languageId}`);
};

export const treeListUpdateTranslations = async (
    languageId,
    listId,
    name,
    description,
    items = []
) => {
    return Api.post(`ListTree/updateTranslation`, {
        languageId,
        listId,
        name,
        description,
        items,
    });
};

export const locationUpdateTranslations = Location.locationUpdateTranslations;

/**
 *
 * @param {string} locationId
 * @param {string} topicId
 * @param {string} searchText
 * @param {string} status
 * @param {string} orderField
 * @param {boolean} ascendingOrder
 * @param {number} pageNumber
 * @param {number} pageSize
 */
export const topicGetAll = async (
    locationId = '',
    topicId = '',
    searchText = '',
    status = '',
    orderField = '',
    ascendingOrder = true,
    pageNumber = 0,
    pageSize = 0
) => {
    const result = await Api.post('/Topic/getAll', {
        locationId: locationId,
        topicId: topicId,
        searchText: searchText,
        status: status,
        orderField: orderField,
        ascendingOrder: ascendingOrder,
        pageNumber: pageNumber,
        pageSize: pageSize,
    });

    return result;
};


const fetchWithCache = async (cache, key, url, method = 'post') => {
// Verifica se já temos os tópicos no cache e se não há carregamento em andamento
  if (cache[key].loading) {
     // Se a requisição já está em progresso, retorna uma promessa que será resolvida após a conclusão
      return new Promise((resolve) => {
          const checkInterval = setInterval(() => {
              if (!cache[key].loading) {
                  clearInterval(checkInterval);
                  resolve(cache[key].data);
              }
          }, 50);  // Verifica a cada 50ms se a requisição foi concluída
      });
  }

  if (cache[key].data) {
      return cache[key].data;
  }
  // Define o estado de carregamento como verdadeiro
  cache[key].loading = true;

  try {
      const result = await Api[method](url, null);
      cache[key] = { data: result, loading: false };// Atualiza o cache e define o carregamento como falso
      return result;
  } catch (error) {
      cache[key].loading = false;// Em caso de erro, redefine o estado de carregamento
      throw error; // Repassa o erro para que o chamador possa tratá-lo
  }
};

// Cache simples para getTopics
const stateCache = {
  getTopics: {
      data: null,
      loading: false, // Controle de estado para evitar chamadas simultâneas
  },
};

// Cache getTopicsByProfile
const stateCacheTopicsProfile = {
  getProfileTopics: {
      data: null,
      loading: false,
  },
};

export const getTopics = async () => {
  return fetchWithCache(stateCache, 'getTopics', '/Topic/getTopics');
};

export const getTopicsByProfile = async () => {
  return fetchWithCache(stateCacheTopicsProfile, 'getProfileTopics', '/Topic/getTopicsByProfile');
};


/**
 *
 * @param {string} locationId
 * @param {string} topicId
 * @param {string} searchText
 * @param {string} status
 * @param {string} orderField
 * @param {boolean} ascendingOrder
 * @param {number} pageNumber
 * @param {number} pageSize
 */
export const topicGetAllSimple = async (
    locationId = '',
    topicId = '',
    searchText = '',
    status = '',
    orderField = '',
    ascendingOrder = true,
    pageNumber = 0,
    pageSize = 0
) => {
    const result = await Api.post('/Topic/getAllDropdown', {
        locationId: locationId,
        topicId: topicId,
        searchText: searchText,
        status: status,
        orderField: orderField,
        ascendingOrder: ascendingOrder,
        pageNumber: pageNumber,
        pageSize: pageSize,
    });

    return result;
};

/**
 * @typedef {Object} topicUpdateParams
 * @property {string} topicId
 * @property {string} languageId
 * @property {string} name
 * @param {topicUpdateParams[]} listTranslations
 */
export const topicUpdateTranslations = async (listTranslations) => {
    const result = await Api.post('/Topic/updateTranslation', listTranslations);
    return result;
};

/**
 *
 * @param {string} locationId
 * @param {string} topicId
 * @param {string} searchText
 * @param {string} status
 * @param {string} orderField
 * @param {boolean} ascendingOrder
 * @param {number} pageNumber
 * @param {number} pageSize
 */
export const formGetAll = async (
    locationId = '',
    topicId = '',
    searchText = '',
    status = '',
    orderField = '',
    ascendingOrder = true,
    pageNumber = 0,
    pageSize = 0
) => {
    const result = await Api.post('/Form/getAllSelect', {
        locationId: locationId,
        topicId: topicId,
        searchText: searchText,
        status: status,
        orderField: orderField,
        ascendingOrder: ascendingOrder,
        pageNumber: pageNumber,
        pageSize: pageSize,
    });

    return result;
};

export const userPicker = async (
    locationId = '',
    topicId = '',
    searchText = ''
) => {
    return await Api.post('/User/getUserPicker', {
        locationId,
        topicId,
        searchText,
    });
};

/**
 *
 * @param {string} locationId
 * @param {string} topicId
 * @param {string} searchText
 * @param {string} status
 * @param {string} orderField
 * @param {boolean} ascendingOrder
 * @param {boolean} editAssignedTo
 * @param {number} pageNumber
 * @param {number} pageSize
 */
export const userGetAll = async (
    locationId = '',
    topicId = '',
    searchText = '',
    status = '',
    orderField = '',
    ascendingOrder = true,
    pageNumber = 0,
    pageSize = 0,
    locationDirectParents = false,
    editAssignedTo = false,
    includeProfiles = true
) => {
    return User.getAll({
        locationId: locationId,
        topicId: topicId,
        searchText: searchText,
        status: status,
        orderField: orderField,
        ascendingOrder: ascendingOrder,
        pageNumber: pageNumber,
        pageSize: pageSize,
        locationDirectParents: locationDirectParents,
        editAssignedTo: editAssignedTo,
        includeProfiles: includeProfiles,
    });
};

/**
 * @param {string} entityId
 * @param {import('../pages/FormBuilder/const').ENTITY_TYPE} entityType
 * @param {boolean} withDefaultLanguage
 * @returns { Promise<import('axios').AxiosResponse<{statusCode: number, response: Array<{id: string, name:string, countryCode:string, countryName:string, isDefault: boolean}>}> }
 */
export const getAvailableLanguages = async (
    entityType,
    withDefaultLanguage = true,
    entityId = undefined
) => {
    let result = await Api.post('/Language/getAvailableLanguages', {
        entityId: entityId,
        entityType: entityType,
        withDefaultLanguage: withDefaultLanguage,
    });
    return result;
};

/**
 * @param {string} userId
 */
export const userGetById = async (userId) => {
    const result = await Api.get(`/User/getUserSimpleById?userId=${userId}`);

    return result;
};

/**
 * @param {string} img
 */
export const getUrlImage = async (img) => {
    const r = await Api.get(`/FormTask/getFile/${img}`, {
        responseType: 'blob',
    });
    let url = window.URL.createObjectURL(r.data);
    return url;
};

export const listUpdate = async (body) => {
    const result = await Api.post('/List/update', body);
    return result;
};

export const listSimpleGetAll = async (
    languageId = '',
    locationId = '',
    topicListId = [],
    orderField = '',
    ascendingOrder = true
) => {
    const result = await Api.post('/List/simpleGetAll', {
        languageId: languageId,
        locationId: locationId,
        topicListId: topicListId,
        orderField: orderField,
        ascendingOrder: ascendingOrder,
    });

    return result;
};

export const listGetById = async (id = '', ordering = '') => {
    const result = await Api.get(`/List/get?id=${id}&ordering=${ordering}`);

    return result.data.response;
};

export const listGetByIdSimple = async (
    id = '',
    ordering = '',
    showAtrs = false,
    isFilteringByLocation = false,
    language = null
) => {
    const result = await Api.get(
        `/List/getSimple?id=${id}&languageId=${language}&ordering=${ordering}&showAtrs=${showAtrs}&isFilteringByLocation=${isFilteringByLocation}`
    );

    return result.data.response;
};

export const featureGetAllFiltered = async (featureList = []) => {
    const result = await Api.post('/Feature/getAllFiltered', featureList);

    return result.data;
};

export const getTenant = async () => {
    const result = await Api.get('/Tenant/get', null);

    return result.data;
};

export const updateTenant = (obj) => {
    return Api.post('/Tenant/update', obj);
};

export const roleGetAllFiltered = async (roleList = []) => {
    const result = await Api.post('/Role/getAllFiltered', roleList);

    return result.data;
};

export const featureGetAll = async () => {
    const result = await Api.get('/Feature/getAll');

    return result.data;
};

export const roleGetAll = async () => {
    const result = await Api.get('/Role/getAll');

    return result.data;
};

export const roleDelete = async (roleId) => {
    const result = await Api.post(`/Role/delete?roleId=${roleId}`);

    return result.data;
};

// export const roleInsertOrUpdate = async permissionJsonApi => {
//   let method = permissionJsonApi.id === '' ? 'register' : 'update';
//   const result = await Api.post(`/Role/${method}`, permissionJsonApi);
//   return result;
// };
export const roleInsert = async (permissionJsonApi) => {
    const result = await Api.post(`/Role/register`, permissionJsonApi);
    return result;
};

export const roleUpdate = async (permissionJsonApi) => {
    const result = await Api.post(`/Role/update`, permissionJsonApi);
    return result;
};

export const languageGetAll = async () => {
    const result = await Api.get('/Language/getAll');
    return result;
};

export const FormTaskGet = (id, languageId, relatedTasks) => {
    let params = `?id=${id}`;
    if (languageId) {
        params += `&languageId=${languageId}`;
    }
    if (relatedTasks) {
        params += `&relatedTasks=${relatedTasks}`;
    }
    return Api.get(`/FormTask/get${params}`);
};

export const GetUserRolesById = async (dateTimeNow) => {
    //README: temos de encodar o datetime pois ao enviar expirationDate=2021-07-28T18:58:02+01:00
    // o caracter + é convertido para espaço e acabava ocorrendo erro e deslogando o usuario
    // por isso estamos utilizando o encodeURIComponent.
    dateTimeNow = encodeURIComponent(dateTimeNow);
    const result = await Api.get(
        `/User/getUserRolesById?expirationDate=${dateTimeNow}`
    );
    return result;
};

export const GetUserTasksPending = async (my) => {
    const result = await Api.get(`/FormTask/PendingTasks/${my}`);
    return result;
};

export const FormTaskRegister = (obj) => {
    return Api.post('/FormTask/register', obj);
};

export const FormTaskUpdate = (obj) => {
    return Api.post('/FormTask/update', obj);
};
