import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, Popconfirm, Row, Select, Tabs, Timeline } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectSearch } from '../../components/SelectSearch';
import { success_message, warning_message } from '../../components/Toastr';
import { TreeSelectLocationBase } from '../../components/TreeSelectLocation';
import { ACTION, FEATURE } from '../../config/roles';
import { useAuth } from '../../hooks/auth';
import Api, { Api_v2, exceptionNotificationAPI, userGetById } from '../../services/api';
import { getMomentLocaleLanguage } from '../../utils/helper';
import { DEFAULT_DATE_FORMAT } from '../../utils/string_constants';
import { TASK_STATUS } from '../FormBuilder/const';
import { ContentModal, MessageInfo } from './styles';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const ScheduleForm = ({ setShowModalForm, formTaskSelected, dateSelected, getTaskScheduleListData, }) => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const { user, can } = useAuth();
    const [justification, setJustification] = useState(true);
    const [scheduleData, setScheduleData] = useState();
    const [scheduleDataRec, setScheduleDataRec] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [selectedForm, setSelectedForm] = useState();
    const [baseLocation, setBaseLocation] = useState();
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    // User Dropdown
    const [usersDropdownLoading, setUsersDropdownLoading] = useState(false);
    const [usersDropdownOptions, setUsersDropdownOptions] = useState([]);
    const [usersDropdownOptionsConfig, setUsersDropdownOptionsConfig] =
        useState({
            locationId: '',
            topicId: '',
            searchText: '',
            status: '',
            orderField: 'Name',
            ascendingOrder: undefined,
            pageNumber: 0,
            pageSize: 0,
            locationDirectParents: true,
            editAssignedTo: true,
            includeProfiles: false,
        });

    // Form Dropdown
    const [formsDropdownDisabled, setFormsDropdownDisabled] = useState(false);
    const [formsDropdownOptions, setFormsDropdownOptions] = useState([]);
    const [formsDropdownOptionsConfig, setFormsDropdownOptionsConfig] =
        useState({
            locationDirectParents: true,
            topicId: '',
            searchText: '',
            status: '',
            orderField: 'Name',
            ascendingOrder: undefined,
            pageNumber: 0,
            pageSize: 0,
            status: '',
            validatePeriod: false,
            includeExtras: false,
        });

    const [formSelectedNotHasActivatePeriod, setFormSelectedNotHasActivatePeriod,] = useState({ message: '', status: false, });

    const [type, setType] = useState('event');
    const [indeterminate, setIndeterminate] = useState(true);
    const [option, setOption] = useState('notrepeat');

    useEffect(() => {
        form.resetFields();
        setOption('notrepeat');
        setType('event');
        setIndeterminate(true);
        let isAdmin = 0;
        if (user.profiles != undefined && user.profiles.filter((f) => f.role.name === 'Administrator').length > 0) {
            isAdmin = 1;
        }

        let formSelected = {};
        if (formTaskSelected != null && formTaskSelected.id) {
            const opt = formTaskSelected.extendedProps.option == '' ? 'notrepeat' : formTaskSelected.extendedProps.option;
            const scheduleDateSelected = dayjs(formTaskSelected.extendedProps.scheduleDate);
            formSelected = {
                id: formTaskSelected.id,
                userId: isAdmin || formTaskSelected.extendedProps.userId ? formTaskSelected.extendedProps.userId : user.id,
                scheduleDate: scheduleDateSelected || dayjs(),
                endDate: scheduleDateSelected.clone().add(7, 'days') || dayjs(),
                formScheduleRecurrencyStart: formTaskSelected.extendedProps.formScheduleRecurrencyStart || dayjs(),
                formScheduleRecurrencyEnd: (formTaskSelected.extendedProps.formScheduleRecurrencyEnd != null && formTaskSelected.extendedProps.formScheduleRecurrencyEnd != undefined) ? formTaskSelected.extendedProps.formScheduleRecurrencyEnd : dayjs(),
                message: formTaskSelected.extendedProps.message ? formTaskSelected.extendedProps.message : '',
                option: opt,
            };


            setJustification(false);

            //scheduledate using to validade hasChangeUserOrScheduleDate
            setScheduleData(formSelected.scheduleDate);
            if (formSelected.formScheduleRecurrencyStart) {
                setScheduleDataRec(formSelected.formScheduleRecurrencyStart);
            }

            const taskStatus =
                TASK_STATUS[formTaskSelected.extendedProps.status];
            let formsDropdownDisabled = true;
            if (!formTaskSelected.extendedProps.correlationId && taskStatus !== TASK_STATUS.Done && taskStatus !== TASK_STATUS.Open) {
                formsDropdownDisabled = false;
            } else {
                formsDropdownDisabled = true;
            }
            setFormsDropdownDisabled(formsDropdownDisabled);

            setLoadingSubmit(true);
            Api_v2.Form.get(formTaskSelected.extendedProps.formId).then((r) => {
                setLoadingSubmit(false);
                const FORM = r.data.response;
                if (formsDropdownDisabled) {
                    setFormsDropdownOptions([
                        {
                            label: FORM.title,
                            value: FORM.id,
                            locationId: FORM.locationId,
                        },
                    ]);

                    form.setFieldsValue({
                        formId: FORM.id,
                    });

                    updateUserDropdownOptions(FORM.locationId).then((r) => {
                        const user = r.find((u) => u.value === formSelected.userId);
                        if (user) {
                            setBaseLocation(user.companyLocationId);
                        } else {
                            invalidUserForSelectedForm(formTaskSelected.extendedProps.userName, formSelected.userId, FORM.title, FORM.id, FORM.locationId);
                        }

                        form.setFieldsValue({
                            location: formTaskSelected.extendedProps.locationId,
                            formId: FORM.id,
                        });
                    });
                } else {
                    updateUserDropdownOptions(FORM.locationId).then((r) => {
                        const user = r.find((u) => u.value === formSelected.userId);
                        if (user) {
                            setSelectedUser({
                                id: formSelected.userId,
                                companyLocationId: user.companyLocationId,
                                locationId: user.locationId,
                            });
                            setBaseLocation(user.companyLocationId);
                        } else {
                            invalidUserForSelectedForm(formTaskSelected.extendedProps.userName, formSelected.userId, FORM.title, FORM.id, FORM.locationId);
                        }
                        form.setFieldsValue({
                            location: formTaskSelected.extendedProps.locationId,
                            formId: FORM.id,
                        });
                    });
                }
            });

            setIndeterminate(formTaskSelected.extendedProps.indeterminate);
            setOption(opt);
        } else {
            const momentDateSelected = dayjs(dateSelected);
            formSelected = {
                userId: user.id,
                scheduleDate: momentDateSelected,
                endDate: momentDateSelected.clone().add(7, 'days'),
                formScheduleRecurrencyStart: momentDateSelected.clone(),
                formScheduleRecurrencyEnd: momentDateSelected.clone().add(7, 'days'),
            };
            setScheduleData(momentDateSelected);
            setScheduleDataRec(momentDateSelected);

            updateUserDropdownOptions().then(() => {
                setSelectedUser({
                    id: user.id,
                    companyLocationId: user.companyLocationId,
                    locationId: user.locationId,
                });
                setBaseLocation(user.companyLocationId);
            });
            setFormsDropdownDisabled(false);
        }
        //initial form
        form.setFieldsValue(formSelected);
    }, [formTaskSelected]);

    useEffect(() => {
        if (selectedUser) {
            if (!formsDropdownDisabled) {
                updateFormDropdownOptions(selectedUser.locationId).then((r) => {
                    const formId = form.getFieldValue('formId');
                    if (formId) {
                        const formOption = r.find((u) => u.value === formId);
                        if (!formOption) {
                            form.setFieldsValue({
                                formId: undefined,
                            });
                            setSelectedForm(undefined);
                        }
                    }
                });
            }
        }
    }, [selectedUser]);

    useEffect(() => {
        if (selectedForm) {
            updateUserDropdownOptions(selectedForm.locationId);
        }
    }, [selectedForm]);

    useEffect(() => {
        setFormSelectedNotHasActivatePeriod({ message: '', status: false });
        const formId = form.getFieldValue('formId');
        if (formId && formsDropdownOptions) {
            const form = formsDropdownOptions.find((u) => u.value === formId);
            validateFormActivationPeriod(form.activationDate, form.deactivationDate);
        }
    }, [type]);

    useEffect(() => {
        if (formTaskSelected == null) {
            form.resetFields();
            const momentDateSelected = dayjs(dateSelected);
            form.setFieldsValue({
                userId: user.id,
                scheduleDate: momentDateSelected,
                endDate: momentDateSelected.clone().add(7, 'days'),
                formScheduleRecurrencyStart: momentDateSelected.clone(),
                formScheduleRecurrencyEnd: momentDateSelected.clone().add(7, 'days'),
            });
            setScheduleData(momentDateSelected);
            setScheduleDataRec(momentDateSelected);

            updateUserDropdownOptions().then(() => {
                setSelectedUser({
                    id: user.id,
                    companyLocationId: user.companyLocationId,
                    locationId: user.locationId,
                });
                setBaseLocation(user.companyLocationId);
            });
            setFormsDropdownDisabled(false);
        }
    }, [dateSelected]);

    async function updateUserDropdownOptions(locationId = '') {
        setUsersDropdownOptions([]);
        setUsersDropdownLoading(true);
        const r = await Api.post('/User/getUsersSchedule', {
            locationId,
            topicId: usersDropdownOptionsConfig.topicId,
            searchText: usersDropdownOptionsConfig.searchText,
            locationDirectParents: usersDropdownOptionsConfig.locationDirectParents,
            editAssignedTo: usersDropdownOptionsConfig.editAssignedTo,
            includeProfiles: usersDropdownOptionsConfig.includeProfiles
        });

        const userOptions = r.data.response.map((i) => {
            return {
                label: i.name,
                value: i.id,
                locationId: i.locationId,
                companyLocationId: i.companyLocationId,
            };
        });
        setUsersDropdownOptions(userOptions);
        setUsersDropdownLoading(false);
        return userOptions;
    }

    async function updateFormDropdownOptions(userLocationId) {
        setFormsDropdownOptions(undefined);
        const r = await Api_v2.Form.getAdhocs([userLocationId], formsDropdownOptionsConfig);
        const formOptions = r.data.response.items.map((i) => {
            return {
                label: i.title,
                value: i.id,
                locationId: i.locationId,
                activationDate: i.activationDate,
                deactivationDate: i.deactivationDate,
            };
        });
        setFormsDropdownOptions(formOptions);
        return formOptions;
    }

    function invalidUserForSelectedForm(userName, userId, formLabel, formId, formLocation) {
        setUsersDropdownOptions([
            ...usersDropdownOptions,
            {
                label: userName + ` (This user should not have permission to answer the assigned form. Please, check if the userLocation of the user match the form location. UserId: ${userId})`,
                value: userId,
                locationId: undefined,
                companyLocationId: undefined,
            },
        ]);
        setFormsDropdownOptions([
            ...formsDropdownOptions,
            {
                label: formLabel,
                value: formId,
                locationId: formLocation,
            },
        ]);
        userGetById(userId).then((r) => {
            setBaseLocation(r.data.response.companyLocationId);
        });
    }

    async function schedule() {
        const formTask = form.getFieldsValue();
        if (formTask.userId == '' || formTask.userId == null || formTask.formId == null || formTask.formId == '' || formTask.scheduleDate == '') {
            warning_message('Fill required fields');
            return;
        }
        if (justification && formTask.message == '') {
            warning_message('The justification is required');
            return;
        }
        try {
            let objFormTask = {
                id: formTaskSelected != null ? formTaskSelected.id : '',
                formId: formTask.formId,
                userId: formTask.userId,
                scheduleDate: dayjs(formTask.scheduleDate).format('YYYY-MM-DDTHH:mm:ss'),
                message: formTask.message ? formTask.message : '',
                formScheduleRecurrencyId: formTaskSelected != null ? formTaskSelected.extendedProps.formScheduleRecurrencyId : '',
                scheduleSequence: formTaskSelected != null ? formTaskSelected.extendedProps.scheduleSequence : '',
                locationId: formTask.location,
            };

            let alert = formTaskSelected != null && formTaskSelected.id ? t('Prompt.Update') : t('Prompt.Sent');

            setLoadingSubmit(true);
            await Api.post(`/FormTask/schedule`, objFormTask);
            success_message(alert);
            setShowModalForm(false);
            getTaskScheduleListData();
        } catch (error) {
            exceptionNotificationAPI(error);
        }
        setLoadingSubmit(false);
    }

    async function recurrency() {
        const formTask = form.getFieldsValue();
        if (formTask.userId == '' || formTask.userId == null || formTask.formId == null || formTask.formId == '' || formTask.scheduleDate == '') {
            warning_message('Fill required fields');
            return;
        }
        try {
            let objFormTask = {
                id: formTaskSelected != null ? formTaskSelected.extendedProps.formScheduleRecurrencyId : '',
                formId: formTask.formId ?? formTaskSelected?.extendedProps?.formId,
                userId: formTask.userId ?? formTaskSelected?.extendedProps?.userId,
                start: dayjs(formTask.formScheduleRecurrencyStart).format(DEFAULT_DATE_FORMAT),
                end: formTask.formScheduleRecurrencyEnd ? dayjs(formTask.formScheduleRecurrencyEnd).format(DEFAULT_DATE_FORMAT) : null,
                cron: formTask.option,
                isActive: true,
                indeterminate: indeterminate,
                locationId: formTask.location,
            };

            let alert = formTaskSelected != null && formTaskSelected.id ? t('Prompt.Update') : t('Prompt.Sent');

            setLoadingSubmit(true);
            await Api.post(`/FormTask/recurrency`, objFormTask);
            success_message(`${alert}`);
            setShowModalForm(false);
            getTaskScheduleListData();
        } catch (error) {
            exceptionNotificationAPI(error);
        }
        setLoadingSubmit(false);
    }

    async function deleteSch() {
        try {
            let objFormTask = {
                id: formTaskSelected != null ? formTaskSelected.id : '',
                formId: formTaskSelected?.extendedProps?.formId,
                userId: formTaskSelected?.extendedProps?.userId,
                scheduleDate: formTaskSelected?.extendedProps?.scheduleDate,
                formScheduleRecurrencyId: formTaskSelected != null ? formTaskSelected.extendedProps.formScheduleRecurrencyId : '',
                scheduleSequence: formTaskSelected != null ? formTaskSelected.extendedProps.scheduleSequence : '',
            };

            let alert = t('Prompt.Delete');

            setLoadingSubmit(true);
            await Api.post(`/FormTask/deleteSchedule`, objFormTask);
            success_message(alert);
            setShowModalForm(false);
            getTaskScheduleListData();
        } catch (error) {
            exceptionNotificationAPI(error);
        }
        setLoadingSubmit(false);
    }

    async function deleteRec() {
        try {
            let objFormTask = {
                id: formTaskSelected != null ? formTaskSelected.extendedProps.formScheduleRecurrencyId : '',
            };

            let alert = t('Prompt.Delete');

            setLoadingSubmit(true);
            await Api.post(`/FormTask/inactiveRecurrency`, objFormTask);
            success_message(alert);
            setShowModalForm(false);
            getTaskScheduleListData();
        } catch (error) {
            exceptionNotificationAPI(error);
        }
        setLoadingSubmit(false);
    }

    const hasChangeUserOrScheduleDate = () => {
        let userOption = form.getFieldsValue().userId;
        let scheduleDateOption = form.getFieldValue().scheduleDate;

        if (selectedUser?.id != userOption || !scheduleData.isSame(scheduleDateOption, 'day')) {
            setJustification(true);
        } else {
            setJustification(false);
        }
    };

    const handleChangeUser = (userId) => {
        if (userId) {
            hasChangeUserOrScheduleDate();

            const user = usersDropdownOptions.find((u) => u.value === userId);
            setSelectedUser({
                id: userId,
                companyLocationId: user.companyLocationId,
                locationId: user.locationId,
            });
            setBaseLocation(user.companyLocationId);
        }
    };

    const handleChangeForm = (formId) => {
        if (formId) {
            const form = formsDropdownOptions.find((u) => u.value === formId);
            setSelectedForm({
                id: formId,
                locationId: form.locationId,
                activationDate: form.activationDate,
                deactivationDate: form.deactivationDate, //only use in  end date recurrency
            });
            validateFormActivationPeriod(
                form.activationDate,
                form.deactivationDate
            );
        } else {
            setSelectedForm({
                id: undefined,
                locationId: undefined,
            });
        }
    };

    const handleChangeSchedule = (dateValue, date) => {
        if (dateValue) {
            hasChangeUserOrScheduleDate();
            setScheduleData(dateValue);
            setFormSelectedNotHasActivatePeriod({ message: '', status: false });
            const formId = form.getFieldValue('formId');
            if (formId) {
                const form = formsDropdownOptions?.find((u) => u.value === formId);
                if (form)
                    validateFormActivationPeriod(form.activationDate, form.deactivationDate, dateValue);
            }
        }
    };

    const handleChangeScheduleRec = (dateValue, date) => {
        if (dateValue)
            setScheduleDataRec(dateValue);

        setFormSelectedNotHasActivatePeriod({ message: '', status: false });
        const formId = form.getFieldValue('formId');
        if (formId) {
            const form = formsDropdownOptions?.find((u) => u.value === formId);
            if (form)
                validateFormActivationPeriod(form.activationDate, form.deactivationDate, dateValue);
        }
    };

    const handleCloseModal = () => {
        setFormSelectedNotHasActivatePeriod({ message: '', status: false });
        setShowModalForm(false);
    };

    const validateFormActivationPeriod = (formActivationDate, formDeactivationDate, scheduleDataNew = null) => {
        if (scheduleDataNew === null) {
            scheduleDataNew = type == 'event' ? form.getFieldValue('scheduleDate') : form.getFieldValue('formScheduleRecurrencyStart');
        }

        setFormSelectedNotHasActivatePeriod({ message: '', status: false });
        const scheduleDataSelected = parseInt(dayjs(scheduleDataNew).format('YYYYMMDDHHmmss'));
        const activationDate = parseInt(dayjs(formActivationDate).format('YYYYMMDDHHmmss'));

        let locale = getMomentLocaleLanguage();
        let message = '';
        if (formDeactivationDate) {
            const deactivationDate = parseInt(dayjs(formDeactivationDate).endOf('day').format('YYYYMMDDHHmmss'));
            message = `Info: ${t('Schedule.Modal.FormActivationPeriod')} 
                ${dayjs(formActivationDate, 'YYYY/MMM/DD').locale(locale).format('MMMM[,] Do')} 
                ${t('to')} 
                ${dayjs(formDeactivationDate, 'YYYY/MMM/DD').locale(locale).format('MMMM[,] Do')} 
                ${t('Schedule.Modal.FormActivationPeriod.only')}.
            `;
            if (scheduleDataSelected > deactivationDate) {
                setFormSelectedNotHasActivatePeriod({
                    message,
                    status: true,
                });
            }
        } else {
            message = `Info: ${t('Schedule.Modal.FormActivationPeriod')} 
                ${dayjs(formActivationDate, 'YYYY/MMM/DD').locale(locale).format('MMMM[,] Do')} 
                ${t('Schedule.Modal.FormActivationPeriod.onward')}.
            `;
        }

        if (activationDate > scheduleDataSelected) {
            setFormSelectedNotHasActivatePeriod({
                message,
                status: true,
            });
        }
    };

    const disabledBeforeCurrentdate = (current) => {
        if (selectedForm?.activationDate) {
            let minDate = selectedForm.activationDate ?? '';
            if (minDate != '' && minDate != null)
                return current && current < dayjs(minDate, 'YYYY/MMM/DD');
        }
    };

    return (
        <ContentModal className="font-face-gbook">
            <Tabs
                defaultActiveKey="event"
                activeKey={type}
                tabPosition="left"
                onTabClick={(k) => setType(k)}
            >
                <TabPane tab={t('Schedule.Modal.Event')} key="event">
                    <Form form={form} layout="vertical">
                        <Form.Item
                            label={t('Schedule.Modal.AssignedTo')}
                            className="ant-modal-label-name"
                            name="userId"
                            rules={[
                                {
                                    required: true,
                                    message: t('Required.Custom.Message', {
                                        grammaticalPunct: '!', fieldName: t('Schedule.Modal.AssignedTo').toLowerCase(),
                                    }),
                                    whitespace: true,
                                },
                            ]}
                        >
                            <SelectSearch
                                placeholder={t('Schedule.Modal.AssignedTo')}
                                optionsData={usersDropdownOptions}
                                loading={usersDropdownLoading}
                                disabled={!can(FEATURE.SCHEDULE, [ACTION.DELEGATE]) || usersDropdownLoading}
                                onChange={(value) => handleChangeUser(value)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('Schedule.Modal.Form')}
                            className="ant-modal-label-name"
                            name="formId"
                            rules={[
                                {
                                    required: true,
                                    message: t('Required.Custom.Message', {
                                        grammaticalPunct: '!', fieldName: t('Schedule.Modal.Form').toLowerCase(),
                                    }),
                                    whitespace: true,
                                },
                            ]}
                        >
                            <SelectSearch
                                placeholder={t('Schedule.Modal.Form')}
                                optionsData={formsDropdownOptions}
                                loading={formsDropdownOptions === undefined || usersDropdownLoading ? true : false}
                                disabled={usersDropdownLoading || formsDropdownDisabled}
                                onChange={(form) => handleChangeForm(form)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('Location')}
                            className="ant-modal-label-name"
                            name="location"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TreeSelectLocationBase
                                placeholder={t('Location')}
                                baseLocation={baseLocation}
                                disabled={usersDropdownLoading}
                                loading={usersDropdownLoading}
                                showPath
                            />
                        </Form.Item>

                        {justification && (
                            <Form.Item
                                label={t('Schedule.Modal.Just')}
                                className="ant-modal-label-name"
                                name="message"
                                rules={[
                                    {
                                        required: true,
                                        message: t('Required.Custom.Message', {
                                            grammaticalPunct: '!', fieldName: t('Schedule.Modal.Just').toLowerCase(),
                                        }),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <TextArea showCount maxLength={500} />
                            </Form.Item>
                        )}

                        <Form.Item
                            label={t('DueDate')}
                            className="ant-modal-label-name"
                            name="scheduleDate"
                            rules={[
                                {
                                    type: 'object',
                                    required: true,
                                    message: t('Required.Custom.Message', {
                                        grammaticalPunct: '!', fieldName: t('DueDate').toLowerCase(),
                                    }),
                                },
                            ]}
                        >
                            <DatePicker
                                inputReadOnly
                                disabledDate={disabledBeforeCurrentdate}
                                onChange={(date, dateStr) => handleChangeSchedule(date, dateStr)}
                                showTime
                                format={'YYYY/MMM/DD HH:mm'}
                            />
                        </Form.Item>
                        {formSelectedNotHasActivatePeriod.status && (
                            <MessageInfo>
                                {formSelectedNotHasActivatePeriod.message}
                            </MessageInfo>
                        )}

                        {formTaskSelected != null && formTaskSelected.extendedProps.history.length > 0 && (<Divider orientation="left">Timeline</Divider>)}
                        <Timeline mode="right">
                            {formTaskSelected != null &&
                                formTaskSelected.extendedProps.history.length >
                                0 &&
                                formTaskSelected.extendedProps.history.map(
                                    (e) => {
                                        const event = e.message.split(';');
                                        return (
                                            <Timeline.Item
                                                key={e.message}
                                                dot={<ClockCircleOutlined style={{ fontSize: '16px', }} />}
                                                label={dayjs(e.insertDate).format('YYYY/MMM/DD HH:mm')}
                                            >
                                                {event.map((e2) => {
                                                    const event2 = e2.split(':');
                                                    if (event2.length > 1)
                                                        return (
                                                            <p key={e2}>
                                                                <strong>
                                                                    {event2[0]}:{' '}
                                                                </strong>
                                                                {event2[1]}
                                                            </p>
                                                        );
                                                    else
                                                        return (<p key={e2}>{e2}</p>);
                                                })}
                                            </Timeline.Item>
                                        );
                                    }
                                )}
                        </Timeline>

                        <Row gutter={8} justify="end">
                            <Col>
                                <Button
                                    onClick={() => handleCloseModal()}
                                    className="font-face-gbold"
                                >
                                    Cancel
                                </Button>
                            </Col>

                            {can(FEATURE.SCHEDULE, [ACTION.DELETE]) && formTaskSelected != null && formTaskSelected.id ? (
                                <Col>
                                    <Popconfirm
                                        title={t('Schedule.Pront.Delete')}
                                        onConfirm={() => deleteSch()}
                                        okText={t('BtnYes')}
                                        cancelText={t('BtnNo')}
                                    >
                                        <Button
                                            type="default"
                                            loading={loadingSubmit}
                                            disabled={loadingSubmit}
                                            className="font-face-gbold"
                                        >
                                            {t('OpDelete')}{' '}
                                            {t('Schedule.Title')}
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            ) : null}

                            <Col>
                                <Button
                                    onClick={() => schedule()}
                                    type="primary"
                                    loading={loadingSubmit}
                                    disabled={loadingSubmit}
                                    className="font-face-gbold"
                                >
                                    {formTaskSelected != null && formTaskSelected.id ? t('Schedule.Edit.Schedule') : t('BtnSave')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
                <TabPane
                    tab={t('Schedule.Modal.Recurrency')}
                    disabled={formTaskSelected != null && option == 'notrepeat'}
                    key="schedule"
                >
                    <Form form={form} layout="vertical">
                        {formTaskSelected == null && (
                            <>
                                <Form.Item
                                    label={t('Schedule.Modal.AssignedTo')}
                                    className="ant-modal-label-name"
                                    name="userId"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Required.Custom.Message', {
                                                grammaticalPunct: '!', fieldName: t('Schedule.Modal.AssignedTo').toLowerCase(),
                                            }),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <SelectSearch
                                        placeholder={t('Schedule.Modal.AssignedTo')}
                                        optionsData={usersDropdownOptions}
                                        loading={usersDropdownLoading}
                                        disabled={!can(FEATURE.SCHEDULE, [ACTION.DELEGATE,]) || usersDropdownLoading}
                                        onChange={(value) => handleChangeUser(value)}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={t('Schedule.Modal.Form')}
                                    className="ant-modal-label-name"
                                    name="formId"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Required.Custom.Message', {
                                                grammaticalPunct: '!', fieldName: t('Schedule.Modal.Form').toLowerCase(),
                                            }),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <SelectSearch
                                        placeholder={t('Schedule.Modal.Form')}
                                        optionsData={formsDropdownOptions}
                                        loading={formsDropdownOptions === undefined || usersDropdownLoading ? true : false}
                                        disabled={usersDropdownLoading || formsDropdownDisabled}
                                        onChange={(form) => handleChangeForm(form)}
                                    />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item
                            label={t('Location')}
                            className="ant-modal-label-name"
                            name="location"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TreeSelectLocationBase
                                placeholder={t('Location')}
                                baseLocation={baseLocation}
                                disabled={usersDropdownLoading}
                                loading={usersDropdownLoading}
                                showPath
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('Schedule.Modal.StartDate')}
                            className="ant-modal-label-name"
                            name="formScheduleRecurrencyStart"
                            rules={[
                                {
                                    type: 'object',
                                    required: true,
                                    message: t('Required.Custom.Message', {
                                        grammaticalPunct: '!', fieldName: t('Schedule.Modal.StartDate').toLowerCase(),
                                    }),
                                },
                            ]}
                        >
                            <DatePicker
                                inputReadOnly
                                showTime={false}
                                disabledDate={disabledBeforeCurrentdate}
                                onChange={(date, dateStr) => handleChangeScheduleRec(date, dateStr)}
                                format={'YYYY/MMM/DD HH:mm'}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('Schedule.Modal.Rec.Title')}
                            initialValue="notrepeat"
                            className="ant-modal-label-name"
                            name="option"
                        >
                            <Select
                                value={option}
                                onChange={(e) => { setOption(e); setIndeterminate(true); }}
                            >
                                <Option value="notrepeat">
                                    {t('Schedule.Modal.Rec.Notrepeat')}
                                </Option>
                                <Option value="diary">
                                    {t('Schedule.Modal.Rec.Diary')}
                                </Option>
                                <Option value="diaryMF">
                                    {t('Schedule.Modal.Rec.DiaryMF')}
                                </Option>
                                <Option value="weekly">
                                    {t('Schedule.Modal.Rec.Weekly')}
                                </Option>
                                <Option value="monthly">
                                    {t('Schedule.Modal.Rec.Monthly')}
                                </Option>
                                <Option value="yearly">
                                    {t('Schedule.Modal.Rec.Yearly')}
                                </Option>
                                <Option value="quarterly">
                                    {t('Schedule.Modal.Rec.Quartely')}
                                </Option>
                                <Option value="semester">
                                    {t('Schedule.Modal.Rec.Semester')}
                                </Option>
                            </Select>
                        </Form.Item>

                        {option == 'weekly' && (
                            <>
                                {t('Schedule.Modal.Rec.DofRec')}{' '}
                                <strong>
                                    {scheduleDataRec.format('dddd')}
                                </strong>
                            </>
                        )}

                        {option != 'notrepeat' && (
                            <Form.Item
                                className="ant-modal-label-name"
                                name="indeterminate"
                            >
                                <Checkbox
                                    checked={indeterminate}
                                    onChange={(e) => setIndeterminate(e.target.checked)}
                                >
                                    {t('Schedule.Modal.Rec.Ind')}
                                </Checkbox>
                            </Form.Item>
                        )}

                        {!indeterminate && option != 'notrepeat' && (
                            <Form.Item
                                label={t('Schedule.Modal.EndDate')}
                                className="ant-modal-label-name"
                                name="formScheduleRecurrencyEnd"
                                rules={
                                    option != 'notrepeat'
                                        ? [
                                            {
                                                type: 'object',
                                                required: true,
                                                message: t('Required.Custom.Message', {
                                                    grammaticalPunct: '!', fieldName: t('Schedule.Modal.EndDate').toLowerCase(),
                                                }),
                                            },
                                        ]
                                        : []
                                }
                            >
                                <DatePicker
                                    inputReadOnly
                                    showTime={false}
                                    format={'YYYY/MMM/DD HH:mm'}
                                />
                            </Form.Item>
                        )}
                        {formSelectedNotHasActivatePeriod.status && (
                            <MessageInfo>
                                {formSelectedNotHasActivatePeriod.message}
                            </MessageInfo>
                        )}
                        <Row gutter={8} justify="end">
                            <Col>
                                <Button
                                    onClick={() => handleCloseModal()}
                                    className="font-face-gbold"
                                >
                                    {t('BtnCancel')}
                                </Button>
                            </Col>

                            {can(FEATURE.SCHEDULE, [ACTION.DELETE]) && formTaskSelected != null && formTaskSelected.extendedProps.formScheduleRecurrencyId ? (
                                <Col>
                                    <Popconfirm
                                        title={t('Schedule.Pront.Delete')}
                                        onConfirm={() => deleteRec()}
                                        okText={t('BtnYes')}
                                        cancelText={t('BtnNo')}
                                    >
                                        <Button
                                            type="default"
                                            loading={loadingSubmit}
                                            disabled={loadingSubmit}
                                            className="font-face-gbold"
                                        >
                                            {t('Schedule.Modal.Delete.Recurrency')}
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            ) : null}

                            <Col>
                                <Button
                                    onClick={() => recurrency()}
                                    type="primary"
                                    loading={loadingSubmit}
                                    disabled={loadingSubmit}
                                    className="font-face-gbold"
                                >
                                    {formTaskSelected != null && formTaskSelected.id ? t('Schedule.Modal.Edit.Recurrency') : t('Schedule.Modal.Save.Recurrency')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
            </Tabs>
        </ContentModal>
    );
};

export default ScheduleForm;
