import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nextLanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';

// Validação do localStorage
let getUserDate;
try {
    getUserDate = JSON.parse(localStorage.getItem("@Auth:user"));
} catch (error) {
    getUserDate = null;
}

const loadLangDefault = getUserDate ? getUserDate.languageId : window.navigator.language;

// Função simplificada para obter o idioma
const supportedLanguages = ['pt-BR', 'en-US', 'de-DE', 'es-ES', 'id-ID', 'it-IT', 'nl-NL', 'pl-PL'];
const getLanguage = () => supportedLanguages.includes(loadLangDefault) ? loadLangDefault : 'en-US';

i18n
    .use(Backend)
    .use(initReactI18next)
    .use(i18nextLanguageDetector)
    .init({
        lng: getLanguage(),
        fallbackLng: 'en-US',
        load: "currentOnly",
        debug: false,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json', // Definir caminho dos arquivos de tradução
        },
        interpolation: {
            escapeValue: false,
        },
        useSuspense: false
    });

export default i18n;
