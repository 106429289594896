import { Card, Skeleton } from "antd";
import { Div } from "./styles";

export const Loading = (props) => {
  return (
    <Div props={props}>
      <Card style={{ width: props.width ?? "100%", top: props.top ?? "25px", borderRadius: 5 }}>
        {props.text && <span>{props.text}</span>}
        <Skeleton active />
      </Card>
    </Div>
  );
}