import { TreeSelect as AntTreeSelect } from "antd";
import React, { useState, useEffect } from "react";
import { InlineText } from "./styles";

export function TreeSelect(props) {
    const { getTree, value, showPath, treeData, ...propsToPropagate } = props;
    const [tree, setTree] = useState([]);
    const [loading, setLoading] = useState(props.loading ?? false);
    const [firstLoadCB, setFirstLoadCB] = useState();
    const [treePath, setTreePath] = useState([]);

    useEffect(() => {
        if (tree && tree.length == 0) {
            setTree(treeData);
        }
    }, [tree, treeData])

    useEffect(() => {
        if (getTree) {
            if (value) {
                init();
            }
            else {
                setFirstLoadCB(() => { return onClickFirstLoad });
            }
        }
    }, [getTree]);

    useEffect(() => {
        if (!value) {
            setTreePath([]);
        }

        // This if should fix some situation where the TreeSelect value is Set Asyncronously.
        if (value && firstLoadCB !== undefined) {
            init();
            setFirstLoadCB(undefined);
        }
    }, [value]);

    function init() {
        setTree([{
            id: 1,
            pId: 0,
            title: "Loading...",
            key: value,
            value: value
        }]);

        getTreeData()
            .then((tree) => {
                setTree(tree);
                setTreePath(getTreePath(value, tree));
            });
    }


    function onClickFirstLoad() {
        setFirstLoadCB(undefined);
        getTreeData()
            .then(tree => {
                setTree(tree);
            });
    }

    async function getTreeData() {
        setLoading(true);

        let res;
        try {
            res = await getTree();
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false);
        }

        return res;
    }

    function getTreePath(value, flatTree) {
        const node = flatTree.find(node => node.value === value);
        if (node) {
            const strPath = [];
            let currentNode = node;
            while (currentNode) {
                strPath.unshift(currentNode.title);
                currentNode = currentNode.parent;
            }
            return strPath;
        }

        return [];
    }

    function handleChange(value, label, extra) {
        if (showPath) {
            setTreePath(getTreePath(value, tree));
        }

        // We need to do this because when The Form.Item is the container element it requires it. Without it the AntD '<Form>' will not work properly with this component
        props?.onChange(value, label, extra);
    }

    return (
        <>
            <AntTreeSelect
                {...propsToPropagate}
                /* Value is passed here to make Form.Item propagate the values to the Form properly. This is a very specific situation required by the Ant Form to work */
                value={value}

                /* treeDataSimpleMode IS REQUIRED WHEN USING "showPath" PROPERY!! */
                treeDataSimpleMode={props.treeDataSimpleMode ?? false}

                onChange={handleChange}
                onClick={firstLoadCB}
                allowClear
                showSearch
                treeNodeFilterProp={"title"}
                treeData={tree}
                loading={loading}
                virtual
                dropdownRender={(originNode, props) => {
                    if (loading) {
                        return "Loading...";
                    }
                    return originNode;
                }}
            >
            </AntTreeSelect>
            {
                showPath ?
                    <div style={{ paddingTop: 15 }}>
                        {treePath.map((p, index) => {
                            return (
                                <React.Fragment key={p}>
                                    <InlineText>{p}</InlineText>
                                    {
                                        index === (treePath.length - 1) ?
                                            null
                                            :
                                            <strong>&nbsp;&nbsp;|&nbsp;&nbsp;</strong>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </div>
                    :
                    null
            }
        </>
    );
}
