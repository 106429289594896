import { Menu, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthTokenExpirationMonitor, useAuth } from '../../hooks/auth';
import { useDirty } from '../Blocker/dirty';
import { setIcon } from '../MapperIcon';
import { SidebarMenuOptions } from './menu';
import { Container } from './styles';

const Sidebar = ({ collapsed = false }) => {
    const navigate = useNavigate();
    const { isDirty, setClean } = useDirty();
    const { canAccessPage, user, updateUserPermission } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();
    const menuOptionData = SidebarMenuOptions();

    const [openKeys, setOpenKeys] = useState(['0']);
    const [rootSubmenuKeys, setRootSubmenuKeys] = useState([]);
    const [menuOptions, setMenuOptions] = useState([...menuOptionData]);

    const tryNavigate = (path, newTab) => {
        if (isDirty) {
            Modal.confirm({
                content: t('Prompt.Leave'),
                onOk: () => {
                    setClean();
                    if (newTab) {
                        window.open(path, '_blank');
                    } else {
                        navigate(path);
                    }
                },
            });
        } else {
            if (newTab) {
                window.open(path, '_blank');
            } else {
                navigate(path);
            }
        }
    };

    useEffect(() => {
        let menuKeys = menuOptions.map((menuOption) => menuOption.key);
        setRootSubmenuKeys(menuKeys);
    }, [user.profiles]);

    useEffect(() => {
        if (user.expirationData) {
            setInterval(async () => {
                await updateUserPermission();
            }, 1000000);
        }
    }, []);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    function renderMenuItem(menuOption, showClassStyle) {
        return (
            <Menu.Item
                key={`${menuOption.key}`}
                icon={setIcon(menuOption.icon)}
                className={showClassStyle ? 'menu-item' : 'submenu-item'}
            >
                <a href={`${menuOption.url}`} onClick={(e) => {
                    e.preventDefault();
                    tryNavigate(menuOption.url, menuOption.newPage);
                }}>
                    {t(`${menuOption.name}`)}
                </a>
            </Menu.Item>
        );
    }

    function renderSubMenu(menuOption) {
        return (
            <Menu.SubMenu key={menuOption.key} icon={setIcon(menuOption.icon)}>
                {mountSubMenuItems(menuOption.subMenu)}
            </Menu.SubMenu>
        );
    }

    function mountMenuItem(menuOption = null, showClassStyle = true) {
        if (menuOption == null) {
            return false;
        }

        if (menuOption.name == 'Divider') {
            return (
                <Menu.Divider
                    key={menuOption.key}
                    style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.23)',
                        margin: '0px 0px 14px',
                    }}
                />
            );
        }

        AuthTokenExpirationMonitor;

        if (menuOption.permission.length > 0) {
            return (canAccessPage(menuOption.permission) && renderMenuItem(menuOption, showClassStyle));
        } else {
            return renderMenuItem(menuOption, showClassStyle);
        }
    }

    function mountSubMenuItems(subMenuItems = null) {
        if (subMenuItems == null) {
            return false;
        }

        let showClassStyle = false;
        return subMenuItems.map((subMenu) =>
            mountMenuItem(subMenu, showClassStyle)
        );
    }

    function hasPermissionConfigRoute(subMenuData) {
        let subMenuPermissions = [];
        let RouteHasPermissionDefine = true;
        subMenuData.map((subMenu) => {
            if (subMenu.permission.length > 0) {
                return subMenu.permission.map((perms) => subMenuPermissions.push(perms));
            } else {
                RouteHasPermissionDefine = false;
            }
        });
        return { subMenuPermissions, RouteHasPermissionDefine };
    }

    useEffect(() => {
        setOpenKeys([localStorage.getItem('Flowy.MenuKey')]);
    }, [localStorage.getItem('Flowy.MenuKey')]);

    useEffect(() => {
        if (menuOptions !== undefined && menuOptions.length > 0) {
            menuOptions.map((menu) => {
                if (location.pathname.indexOf(menu.url) > -1 && openKeys.length > 0 && openKeys[0] != menu.key) {
                    localStorage.setItem('Flowy.MenuKey', menu.key);
                }
            });
        }
    }, [openKeys]);

    return (
        <Container>
            <Menu
                theme="dark"
                mode="inline"
                style={{ width: collapsed ? 55 : "100%" }}
                onClick={(e) => localStorage.setItem('Flowy.MenuKey', e.key)}
                openKeys={openKeys}
                selectedKeys={openKeys}
                defaultOpenKeys={openKeys}
                onOpenChange={onOpenChange}
                defaultSelectedKeys={openKeys}
            >
                {menuOptions &&
                    menuOptions.map((menuOption) => {
                        if (menuOption.subMenu.length > 0) {
                            let hasPermissionSubMenu = hasPermissionConfigRoute(menuOption.subMenu);
                            if (hasPermissionSubMenu.RouteHasPermissionDefine) {
                                if (canAccessPage(hasPermissionSubMenu.subMenuPermissions)) {
                                    return renderSubMenu(menuOption);
                                }
                            } else {
                                return renderSubMenu(menuOption);
                            }
                        } else {
                            return mountMenuItem(menuOption);
                        }
                    })}
            </Menu>
        </Container>
    );
};

export default Sidebar;
