import styled from 'styled-components';

export const Header = styled.div`
    text-align: right;

    .fa-icons{
        width: 18px;
        height: 18px;
        opacity: 1;
        cursor: pointer;
        color: #C7C7C7;
    }

    .divider{
        font-size: 40px;
        margin: 0 15px 0 15px;
        top: 0;
    }

    .avatar{
        margin-left: 30px;
        margin-right: 10px;
        vertical-align: middle;
        color: #ffffff;
    }

 `;

 export const Modal = styled.div`
    .modal-div{
        width: 500px;
        height: 200px;
    }
 `

export const CardProfile = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const CardAvatar = styled.div`
    width: 100px;
`

export const CardUserDetails = styled.div`
    float: left;   
`
export const Logo = styled.div`
    .title {
        color: #4085c6;
        font-size: 18px;
        margin-bottom: 0;
        span {
            color: #000;
            font-weight: bold;
        }
    }
`;

