import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/auth";

// Usar `React.memo` para memoizar o `RouteWrapper`
const RouteWrapper = React.memo(({ isPrivate = false, isPublic = false, userHasPermission = true, element: Element, ...props }) => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleNavigation = () => {
      if (!user && isPrivate) {
        navigate("/", { state: { urlRedirect: location.pathname }, replace: true });
        return;
      }

      if (user && user.userName !== "anonymous") {
        if (props?.path?.includes("/an/")) {
          signOut();
          return;
        }

        if (!isPrivate && !isPublic) {
          navigate("/home", { replace: true });
          return;
        }

        if (!userHasPermission) {
          navigate("/home", { replace: true });
          return;
        }

        if (user.isFirstLogin || user.expiredPassword) {
          navigate("/changePassword");
          return;
        }
      }

      if (user && user.userName === "anonymous" && !location.pathname.includes("/an/")) {
        signOut();
        navigate("/", { replace: true });
      }
    };

    handleNavigation();
  }, [user, isPrivate, isPublic, userHasPermission, navigate, location.pathname, signOut, props?.path]);

  return <Element {...props} />;
});

export default RouteWrapper;