import styled from "styled-components";

export const Container = styled.div`
    .trigger {
      font-size: 18px;
      color: #c7c7c7;
    }

    .maintenance-message{
        color: red !important;
        font-weight: 600;
        max-width: 50%;
        line-height: 18px;
        white-space: normal;
    }
    .maintenance-message.ant-menu-item-selected {
        color: red !important;
    }
    .maintenance-message:hover{
        color: red !important;
    }
    .trigger:hover {
        color: #4085c6;
    }

    .site-layout {
        background: #fff;
    }

    .site-layout .ant-layout-sider-collapsed {
        width: 55px !important;
        max-width: 55px !important;
        min-width: 55px !important;
    }

    .site-layout-background {
        padding: 0;
        background: #fff;
        box-shadow: 4px 3px 8px 1px #969696;
    }

    #site-content-id {
        overflow: auto;
        background-color: #f8f8f8;
        min-height: 280px;
    }

    .content-layout {
        background-color: #eeeeee;
        margin: 24px 16px;
        padding: 24px;
        min-height: 280px;
    }

    .ant-layout-sider-children {
        height: 100%;
        margin-top: -0.1px;
        padding-top: 0.1px;
        background: var(--sidebar-background);
    }

    & .ant-menu.ant-menu-dark, 
    .ant-menu-dark .ant-menu-sub, 
    .ant-menu.ant-menu-dark .ant-menu-sub {
      background: var(--sidebar-background) !important;
    }

    & .ant-menu-item,
    & .ant-menu-item:hover {
        border-bottom: 0 !important;
    }

    & .ant-menu-item.logo-item svg {
        width: auto;
        height: 100%;
    }
`;

export const Logo = styled.div`
    .title {
        color: #4085c6;
        font-size: 20px;
        line-height: 60px;
        margin-bottom: 0;
        span {
            color: #000;
            font-weight: bold;
        }
    }
`;

export const HeaderLogoContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
`;