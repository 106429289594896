import moment from 'moment-timezone';
import { FORM_STATUS, TASK_STATUS } from '../FormBuilder/const';

export const parseFormListToTable = formlists => {
  let formlist = [];
  moment.locale('en');
  formlists.response.items.map(formItem => {
    formlist.push({
      ...formItem,
      key: `${formItem.id}`,
      id: `${formItem.id}`,
      title: `${formItem.title ? formItem.title : ''}`,
      description: `${formItem.description ? formItem.description : ''}`,
      topic: `${formItem.topic ? formItem.topic.name : ''}`,
      bookmark: formItem.bookmark,
      created_at: `${formItem.createdAt ? moment(formItem.createdAt).format('DD/MMM/YYYY') : ''}`,
      status: [`${Object.entries(FORM_STATUS)[formItem.status][0]}`],
    });
  });
  return formlist;
};

export const buildFormStatusList = () => {
  let statusList = [];
  Object.entries(FORM_STATUS).map(statusItem => {
    statusList.push({
      id: `${statusItem[0]}`,
      name: `${statusItem[0]}`,
    });
  });
  return statusList;
};

export const parseTaskListToTable = tasklists => {
  var list = tasklists.response.items ?? tasklists.response;
  const tasklist = list.map(taskItem => {
    return {
      ...taskItem,
      key: `${taskItem.id}`,
      id: `${taskItem.id}`,
      approvers: taskItem.approvers,
      taskId: `${taskItem.taskId}`,
      taskTypeId: `${taskItem.taskTypeId}`,
      friendlyId: `${taskItem.friendlyId}`,
      documentId: `${taskItem.documentId}`,
      name: `${taskItem.name ? taskItem.name : ''}`,
      title: `${taskItem.name ? taskItem.name : ''}`,
      status: taskItem.status,
      description: taskItem.description,
      start: moment(taskItem.scheduleDate).format(),
      scheduleDate: taskItem.scheduleDate ? moment(taskItem.scheduleDate).format() : undefined,
      startDate: taskItem.startDate ? moment(taskItem.startDate, 'YYYY/MMM/DD') : undefined,
      endDate: taskItem.endDate ? moment(taskItem.endDate, 'YYYY/MMM/DD') : undefined,
      option: `${taskItem.option ? taskItem.option : ''}`,
      formId: `${taskItem.formId ? taskItem.formId : ''}`,
      userId: `${taskItem.userId ? taskItem.userId : ''}`,
      userName: `${taskItem.userName ? taskItem.userName : ''}`,
      history: taskItem.history,
      description: taskItem.description,
      formScheduleRecurrencyId: taskItem.formScheduleRecurrencyId,
      formScheduleRecurrencyStart: taskItem.formScheduleRecurrencyStart ? moment(taskItem.formScheduleRecurrencyStart) : undefined,
      formScheduleRecurrencyEnd: taskItem.formScheduleRecurrencyEnd ? moment(taskItem.formScheduleRecurrencyEnd) : undefined,
      scheduleSequence: taskItem.scheduleSequence,
      formSequence:taskItem.formSequence,
    };
  });
  return tasklist;
};

export const buildTaskStatusListWithoutDoneCanceled = () => {
  let statusList = [];
  Object.entries(TASK_STATUS).map(statusItem => {
    if (statusItem[0] != "Done")
      statusList.push({
        id: `${statusItem[0]}`,
        name: `${statusItem[0]}`,
      });
  });
  return statusList;
};

export const buildTaskStatusList = () => {
  let statusList = [];
  Object.entries(TASK_STATUS).map(statusItem => {
    statusList.push({
      id: `${statusItem[0]}`,
      name: `${statusItem[0]}`,
    });
  });
  return statusList;
};

const parseCorrelatedWorkflowInstances = (correlatedInstances) => {
  if (correlatedInstances) {
    const parsedInstances = correlatedInstances.map(i => {

      let startedDate;
      if (i.startedDate) {
        startedDate = moment(i.startedDate);
      }

      return {
        startedDate: startedDate,
        name: i.name,
        status: i.status,
        instanceId: i.instanceId,
        ondeParou: i.ondeParou,
      }

    });

    return parsedInstances;
  }
}

export const parseFormListAnswerToTable = (formlistAnswers) => {
  const formlistAnswer = formlistAnswers.response.items.map(formItem => {
    return {
      ...formItem,
      key: `${formItem.id}`,
      formId: `${formItem.formId}`,
      correlationId: `${formItem.correlationId ? formItem.correlationId : ''}`,
      id: `${formItem.id}`,
      title: `${formItem.formName ? formItem.formName : ''}`,
      description: `${formItem.description ? formItem.description : ''}`,
      user: `${formItem.userName ? formItem.userName : ''}`,
      topic: `${formItem.topicName ? formItem.topicName : ''}`,
      scheduleDate: formItem.scheduleDate ? moment(formItem.scheduleDate, "YYYY-MMM-DD HH:mm") : undefined,
      endDate: formItem.endDate ? moment(formItem.endDate, "YYYY-MMM-DD HH:mm") : undefined,
      status: [`${formItem.status}`],
      correlatedWorkflowInstances: parseCorrelatedWorkflowInstances(formItem.correlatedWorkflowInstances),
    };
  });
  return formlistAnswer;
}

export const buildStatusAnswerList = () => {
  let statusList = [];
  Object.entries(TASK_STATUS).map(statusItem => {
    statusList.push({
      id: `${statusItem[0]}`,
      name: `${statusItem[0]}`,
    });
  });
  return statusList;
}