import React, { createContext, useContext, useState } from "react";

const DirtyContext = createContext();

export function DirtyProvider({ children }) {
  const [isDirty, setIsDirty] = useState(false);

  const setDirty = () => {
    setIsDirty(true);
  };

  const setClean = () => {
    setIsDirty(false);
  };

  return (
    <DirtyContext.Provider value={{ isDirty, setDirty, setClean }}>
      {children}
    </DirtyContext.Provider>
  );
}

export function useDirty() {
  return useContext(DirtyContext);
}