export function getMomentLocaleLanguage() {
    switch (localStorage.getItem("i18nextLng")) {
        case 'pt-BR':
            return 'pt-br';
        case 'en-US':
            return 'en';
        case 'de-DE':
            return 'de';
        case 'es-ES':
            return 'es';
        case 'id-ID':
            return 'id';
        case 'it-IT':
            return 'it';
        case 'nl-NL':
            return 'nl';
        case 'pl-PL':
            return 'pl';
        default:
            return 'en';
    }
}

/**
 * 
 * @param {seconds} timestampEnd 
 * @returns 
 */
import moment from 'moment';
export function getTimestampDate(timestampEnd = null) {
    let timestampNow = Math.floor(moment().utc().valueOf() / 1000);
    if (timestampEnd != null) {
        timestampNow = (timestampNow + timestampEnd);
    }
    return timestampNow;
}

export function setExpirationUserData() {
    const userLogged = JSON.parse(localStorage.getItem('@Auth:user'));
    userLogged.expirationData = 1262347200; //old date: 01/01/2010 force do download roles
    let user = { ...userLogged };
    localStorage.setItem('@Auth:user', JSON.stringify(user));
}