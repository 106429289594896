import { BellOutlined, FolderOutlined, MenuFoldOutlined, MenuUnfoldOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Badge, Col, Dropdown, Layout, Menu, Modal, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import { useDirty } from '../../../components/Blocker/dirty';
import HeaderOptions from '../../../components/Header';
import IconAppLogo from "../../../components/IconAppLogo";
import SidebarMenu from '../../../components/Sidebar';
import { ACTION, FEATURE } from "../../../config/roles";
import { useAuth } from "../../../hooks/auth";
import Api from '../../../services/api';
import { Container, HeaderLogoContainer } from './styles';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

const Authorized = ({ children }) => {
  const { isDirty, setClean } = useDirty();

  const { user, can, } = useAuth();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const [maintenanceMessage, setMaintenanceMessage] = useState(localStorage.getItem('@Auth:MaintenanceMessage'));
  const [pendingTasks, setPendingTasks] = useState(JSON.parse(localStorage.getItem('@Auth:CheckedTasksCount')));
  let navigate = useNavigate();

  const tryNavigate = (path, newTab) => {
    if (isDirty) {
      Modal.confirm({
        content: t('Prompt.Leave'),
        onOk: () => {
          setClean();
          navigate(path);
        },
      });
    } else {
      navigate(path);
    }
  };

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const checkContentLayoutMarginCss = () => {
    if (document.getElementById("site-content-id").style.margin === "0px") {
      document.getElementById("site-content-id").style.margin = '24px 16px';
      document.getElementById("site-content-id").style.padding = '24px';
      document.getElementById("site-content-id").style.background = '#F8F8F8';
    }
  }

  const confirmRead = async () => {
    var config = {
      content: t('ConfirmReadText'),
      okText: t('BtnYes'),
      onOk() {
        localStorage.removeItem('@Auth:MaintenanceMessage', '');
        setMaintenanceMessage('');
        Api.post('/MaintenanceConfirm', null);
      }
    };

    return confirm(config);
  }

  const notify = [
    { label: `${t('History.LateTasks')}`, key: 'lateTasks' },
    { type: 'divider' },
  ];

  if (pendingTasks != null) {
    notify.push({
      label: <><Text type="secondary">{t('History.MyTasks')}:</Text> {pendingTasks.myTasks} <Text type="secondary" style={{ float: "right", cursor: "pointer" }}></Text></>,
      key: 'tasks'
    });

    if (can(FEATURE.FORMTASK, [ACTION.PENDINGTASK])) {
      notify.push({
        label: <><Text type="secondary">{t('History.LocationTasks')}:</Text> {pendingTasks.locationTasks} <Text type="secondary" style={{ float: "right", cursor: "pointer" }}></Text></>,
        key: 'tasks2'
      });
      notify.push({
        label: <> <Text type="secondary" > {t('History.ReportTasks')}:</Text > {pendingTasks.reportTasks} < Text type="secondary" style={{ float: "right", cursor: "pointer" }}></Text ></>,
        key: 'tasks3'
      });
    }
  }

  const notify2 = [
    { label: `${t('History.LateTasks')}`, key: 'lateTasks' },
    { type: 'divider' },
  ];

  if (pendingTasks != null)
    notify2.push({
      label: <>
        <Text type="secondary">{t('Menu.DocumentControl')}:</Text> {pendingTasks.documentTasks} <Text type="secondary" style={{ float: "right", cursor: "pointer" }}></Text>
      </>, key: 'tasks'
    });

  return (
    <Container>
      <Layout style={{ height: '100vh' }}>
        <Header className="site-layout-background">
          <Menu mode="horizontal" style={{ padding: "7px 0", background: "#fff" }}>
            <Menu.Item key={1}>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  id: 'menu-id',
                  onClick: handleToggle,
                },
              )}
            </Menu.Item>
            <Menu.Item key={2} className="logo-item">
              <Link to="#" onClick={() => { localStorage.setItem("Flowy.MenuKey", "0"); tryNavigate(user.isFirstLogin || user.expiredPassword ? "/changePassword" : (user && user.customHomePage ? '/home/custom' : '/home')); }}>
                <HeaderLogo />
              </Link>
            </Menu.Item>
            <Menu.Item key={3} className="maintenance-message">
              {maintenanceMessage != null && maintenanceMessage != "" && maintenanceMessage != 'null' && <Row onClick={() => confirmRead()}><Col><ScheduleOutlined />{maintenanceMessage} <span>{t("ConfirmRead")}</span></Col></Row>}
            </Menu.Item>
            <Menu.Item key={4} style={{ marginLeft: "auto" }}></Menu.Item>
            {can(FEATURE.DOCUMENTCONTROL, [ACTION.DCCOMMON]) && user && !user.customHomePage &&
              <Menu.Item key={5} style={{ marginTop: 4, marginRight: 0 }}>
                {(pendingTasks != null && (user.isFirstLogin == false && user.expiredPassword == false)) &&
                  <Dropdown menu={{ items: notify2 }} trigger={['click']}>
                    <Badge count={pendingTasks.documentTasks}>
                      <FolderOutlined style={{ color: "#000000 !important", fontSize: 20, width: 30, textAlign: "right" }} />
                    </Badge>
                  </Dropdown>
                }
              </Menu.Item>
            }
            <Menu.Item key={6} style={{ marginTop: 4, marginRight: 0 }}>
              {(pendingTasks != null && (user.isFirstLogin == false && user.expiredPassword == false)) && user && !user.customHomePage &&
                <Dropdown menu={{ items: notify }} trigger={['click']}>
                  <Badge count={can(FEATURE.FORMTASK, [ACTION.PENDINGTASK]) ? (pendingTasks.myTasks + pendingTasks.locationTasks + pendingTasks.reportTasks) : pendingTasks.myTasks}>
                    <BellOutlined style={{ color: "#000000 !important", fontSize: 20, width: 30, textAlign: "right" }} />
                  </Badge>
                </Dropdown>
              }
            </Menu.Item>
            <Menu.Item key={7} style={{ marginLeft: 0 }}><HeaderOptions /></Menu.Item>
          </Menu>
        </Header>

        <Layout className="site-layout">
          {!user.isFirstLogin && !user.expiredPassword &&
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              onClick={() => checkContentLayoutMarginCss()}
            >
              <SidebarMenu collapsed={collapsed} />
            </Sider>
          }
          <Content id="site-content-id">{children}</Content>
        </Layout>

      </Layout >
    </Container >
  );
};

const HeaderLogo = () => {
  return (
    <HeaderLogoContainer>
      <IconAppLogo />
      {window.location.hostname.indexOf('app.flowy.cc') == -1 && <span style={{ fontWeight: 500, textTransform: "uppercase" }}>{window.location.hostname.indexOf('app-qa.flowy.cc') > -1 ? "QA" : "DEV"} environment</span>}
    </HeaderLogoContainer >
  )
}

export default Authorized;
