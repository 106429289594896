import Api from "../api";
var basePath = "/ListTree";
export function get(id) {
    var includeUsers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return Api.get("".concat(basePath, "/items/get?id=").concat(id, "&includeUsers=").concat(includeUsers));
}
export function getAll(listTreeId) {
    return Api.post("".concat(basePath, "/items/getAll?ListTreeId=").concat(listTreeId));
}
export function getAllLazy(request) {
    return Api.post("".concat(basePath, "/items/getAllLazy"), request);
}
export function originOfInformation(listTreeId, formId) {
    var strParams = "?listTreeId=".concat(listTreeId);
    if (formId) {
        strParams += "&formId=".concat(formId);
    }
    return Api.get("".concat(basePath, "/items/origin-of-information").concat(strParams));
}
export function importFile(file, listTreeId) {
    var formData = new FormData();
    formData.append("file", file);
    return Api.post("".concat(basePath, "/items/import-from-file?listTreeId=").concat(listTreeId), formData);
}
export function register(params) {
    return Api.post("".concat(basePath, "/items/register"), params);
}
export function remove(id) {
    return Api.post("/ListTree/items/delete?id=".concat(id));
}
export function update(params) {
    return Api.post("".concat(basePath, "/items/update"), params);
}
