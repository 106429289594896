import { DownOutlined, ImportOutlined, OrderedListOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Drawer, Dropdown, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../hooks/auth";
import Profile from "../../pages/Profile";
import { useDirty } from '../Blocker/dirty';
import { Header } from './styles';

const HeaderNavbar = () => {
  const { isDirty, setClean } = useDirty();
  const { t } = useTranslation();
  const { signOut, user } = useAuth();
  const [visibleShowDrawer, setVisibleDrawer] = useState(false);
  const [tenant, setTenant] = useState(localStorage.getItem('@Auth:tenant'));
  let navigate = useNavigate();

  const tryNavigate = (path, newTab) => {
    if (isDirty) {
      Modal.confirm({
        content: t('Prompt.Leave'),
        onOk: () => {
          setClean();
          navigate(path);
        },
      });
    } else {
      navigate(path);
    }
  };

  const items = [
    { label: `${t('Header.Modal.Name')}: ${user.name}`, key: 'name' },
    { label: `${t('Header.Modal.Email')}: ${user.email}`, key: 'email' },
    { label: `${t('User.User.Location')}: ${user.locationName}`, key: 'location' },
    { label: `${t('Login.Tenant')}: ${tenant}`, key: 'tenant' },
    { type: 'divider' },
    { label: <span onClick={() => setVisibleDrawer(true)}><UserOutlined /> {t('Header.Modal.ChangePassword')}</span>, key: 'change-password' },
    { label: <span onClick={signOut}><ImportOutlined /> {t('Header.Modal.Logout')}</span>, key: 'logout' },
    { type: 'divider' },
    { label: <span onClick={() => tryNavigate("/changelog")}><OrderedListOutlined /> {t('Header.Modal.ChangeLog')}</span>, key: 'changelog' },
    { label: <span onClick={() => tryNavigate("/roadmap")}><OrderedListOutlined /> Roadmap</span>, key: 'roadmap' },
    { type: 'divider' },
    { label: <span><small style={{ color: "#929292" }}>flowy{process.env.REACT_APP_BUILD_VERSION}</small></span>, key: 'version' },
  ];

  return (
    <>
      <Header className='font-face-gbook'>
        <Avatar className='avatar' alt={user.userName} size="large">{user.userName[0].toUpperCase()}</Avatar>
        <Dropdown menu={{ items }} style={{ width: 150 }} trigger={['click']}>
          <a onClick={(e) => e.preventDefault()}>
            {user.userName} <DownOutlined />
          </a>
        </Dropdown>
      </Header>
      <Drawer
        title={<span style={{ fontWeight: "bold" }}>Edit Profile: {user.userName}</span>}
        placement="right"
        closable={false}
        onClose={() => setVisibleDrawer(false)}
        open={visibleShowDrawer}
      >
        <Profile />
      </Drawer>
    </>
  );
};

export default HeaderNavbar;
